import React from "react";
import "./Company.scss";

function Company() {
    return (
        <div className="Section Company-bg">
            <div className="Content Company">
                <h1>About us</h1>
                <p>
                    We are a software company focusing on making and selling backup related utilities. The software on
                    our website has a trial period, and you can use it for a period of time before purchasing to
                    evaluate whether the software is worth buying. At the same time, we welcome any suggestions and
                    opinions. Through your suggestions and our efforts, we can make StaticBackup software better and
                    better.
                </p>
            </div>
        </div>
    );
}

export default Company;
