import React from "react";
import "./Products.scss";

import { ProductNameLine } from "./Common";

import { STATICSYNC_VER, BACKUP8_VER, BACKUP9_VER } from "./Consts";

import Backup8Icon from "./backup8imgs/backup8.png";
import Backup9Icon from "./backup9imgs/Backup9.png";
import StaticSyncIcon from "./StaticSyncIcon.png";

import ButtonPanel from "./ButtonPanel";

const products = [
    {
        index: 0,
        name: "StaticSync",
        icon: StaticSyncIcon,
        version: STATICSYNC_VER,
        desc:
            "Every day we need to deal with various documents, Excel, PPT, Word, etc. Sometimes we need to transfer these documents between different locations. We may use USB disk as a storage medium or synchronize them through the cloud storage. So, we often encounter a problem, which documents are the latest? And which documents are old? If you synchronize these files frequently, you will inevitably use the old files as new files for a moment of negligence, so the loss is sometimes immeasurable. StaticSync is designed to help you synchronize files and avoid inadvertently overwriting useful files.",
        urlDownload: "/download/staticsync",
        urlMore: "/staticsync"
    },
    {
        index: 1,
        name: "Backup9",
        icon: Backup9Icon,
        version: BACKUP9_VER,
        desc: "Every day we use computers to produce a large amount of data, which should belong to you personally, but is stored in the database of large companies. We provide another method to save your personal data. Using Backup9 will effectively back up your personal data to a location, such as another hard disk or your cloud storage. Backup9 uses multi-threading technology, strong encryption and compression to protect and store your data. You can access and restore them anytime, anywhere. At the same time Backup9 also provides a Scheduler to automatically run a backup plan.",
        urlDownload: "/download/backup9",
        urlMore: "/backup9"
    },
    {
        index: 2,
        name: "Backup8",
        icon: Backup8Icon,
        version: BACKUP8_VER,
        desc:
            "It is very difficule to find all those messages, contacts, plugins, settings, favorites from your computer and backup them before you erase your system from your computer. Backup8 will help you to do this job. Just only need few clicks Backup8 will find and backup all necessary personal data from your computer, such as messages, contacts, plugins (from Firefox, Thunderbird), settings (from registry) and favorites to one archive file with high ratio compression. Upgrade system, transfer personal data to another computer or reinstall system now is easy.",
        urlDownload: "/download/backup8",
        urlMore: "/backup8"
    }
];

function ProductItem(props) {
    const { index, name, icon, version, desc, urlDownload, urlMore } = props;
    const clsName = index % 2 === 0 ? "Products-item1" : "Products-item2";
    return (
        <div key={index} className={clsName}>
            <ProductNameLine icon={icon} name={name} version={version} />
            <div className="Products-item-line">
                <p>{desc}</p>
            </div>
            <div className="Products-item-line">
                <ButtonPanel caption="Try it free" url={urlDownload} urlMore={urlMore} />
            </div>
        </div>
    );
}

function Products() {
    return (
        <div className="Section Products-bg">
            <div className="Content Products">
                <h1>Products</h1>
                <div className="Products-list">
                    {products.map(product => {
                        return ProductItem(product);
                    })}
                </div>
            </div>
        </div>
    );
}

export default Products;
