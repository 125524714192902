import React from "react";
import "./Menu.scss";

import MenuItem from "./MenuItem";

function Menu(props) {
    const items = props.items;
    return (
        <div className="Menu">
            {items.map(item => {
                return <MenuItem key={item.caption} caption={item.caption} href={item.href} />;
            })}
        </div>
    );
}

export default Menu;
