import React from "react";

function InfoSupport(props) {
    const { width } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 24 24">
            <path
                fill="#333333"
                d="M14,18 L17,18 L17,11 L20.948254,11 C20.5638881,8.78369289 18.0827415,7 15,7 L9,7 C5.94861235,7 3.48664443,8.74759435 3.06412301,10.932511 L3.06271699,11 L7,11 L7,23 L3,23 C1.34314575,23 1.13889744e-13,21.6568542 1.13686838e-13,20 L1.13686838e-13,14 C1.13578028e-13,13.1115002 0.38625064,12.313214 1,11.7638935 L1,11.6 C1,11.3039312 1.02320474,11.0129877 1.06814212,10.7282447 C1.21456047,5.31741742 5.64395538,1 11.0644529,1 L13,1 C18.5228475,1 23,5.4771525 23,11 L23,11.7638935 C23.6137494,12.313214 24,13.1115002 24,14 L24,20 C24,21.6568542 22.6568542,23 21,23 L17,23 L17,20 L12,20 L10,20 L10,16 L14,16 L14,18 Z M22,14 L21,14 L21,13 L19,13 L19,17.2543508 L19.5992086,16.7407434 L20.9007914,18.2592566 L19,19.8885064 L19,21 L21,21 C21.5522847,21 22,20.5522847 22,20 L22,14 Z M19.2472414,6.0023342 C17.7811664,4.1720715 15.5274592,3 13,3 L11.0644529,3 C8.56566605,3 6.33015367,4.14686996 4.86180306,5.94711091 C6.07365197,5.34433607 7.49225155,5 9,5 L15,5 C16.553207,5 18.0118084,5.3654125 19.2472414,6.0023342 Z M3.02105032,13 L2.99978306,14.0208288 L2,14 L2,20 C2,20.5522847 2.44771525,21 3,21 L5,21 L5,13 L3.02105032,13 Z"
                opacity="1"
            />
        </svg>
    );
}

export default InfoSupport;
