import React from "react";
import "./WhatsNew.scss";

const whatsnewList = [
    {
        time: "3/31/2020",
        title: "StaticBackup9 supports custom backup source. (commit: db571b2)",
        content: () => {
            return (
                <ul>
                    <li>You can add custom backup source from Preferences dialog.</li>
                    <li>Fix the images missing problem from the scheduler.</li>
                </ul>
            );
        }
    },
    {
        time: "3/21/2020",
        title: "StaticBackup9 now is released (for macOS). (commit: 8f3cccf)",
        content: () => {
            return (
                <ul>
                    <li>StaticBackup9 version 1.1 has been released.</li>
                    <li>Visit the link <a href="/backup9">https://www.staticbackup.com/backup9</a> to find more information.</li>
                </ul>
            );
        }
    },
    {
        time: "1/28/2020",
        title: "StaticSync now is portable (under Windows). (commit: f783029)",
        content: () => {
            return (
                <ul>
                    <li>StaticSync version 1.3 portable has been released.</li>
                    <li>Fixed several bugs.</li>
                </ul>
            );
        }
    },
    {
        time: "1/16/2020",
        title: "Fixed an issue with connecting to a Google account with unlimited space. (commit: b41c10a)",
        content: () => {
            return (
                <p>
                    When the user try to connect to an unlimited google account, StaticSync crashed because the server
                    return value no longer contained user space size information. This issue has been fixed, please
                    upgrade to the latest version.
                </p>
            );
        }
    },
    {
        time: "12/30/2019",
        title: "StaticSync Free version has been released!",
        content: () => {
            return (
                <p>
                    Please visit <a href="/downloads">download</a> page to download the install package.
                </p>
            );
        }
    },
    {
        time: "12/27/2019",
        title: "We have completed the program embedding of google drive. (commit: a6f2aa3)",
        content: () => {
            return (
                <>
                    <h3>StaticSync 1.2 has been released</h3>
                    <ul>
                        <li>Now you can use google drive as your cloud sync storage</li>
                        <li>
                            Improved the binding method of cloud storage. Now you can bind multiple cloud storage
                            accounts of the same type, and set different proxy methods for each account.
                        </li>
                        <li>Fixed some bugs and made some small improvements.</li>
                    </ul>
                    <h3>We have upgraded the website</h3>
                    <ul>
                        <li>StaticSync product returns to StaticBackup main website.</li>
                        <li>New interface and color matching will make it easier for you to read.</li>
                        <li>
                            Welcome to subscribe to our mailing list, you will receive the latest information about our
                            software, discount information, etc.
                        </li>
                    </ul>
                </>
            );
        }
    },
    {
        time: "11/26/2019",
        title: "Improve the way to connect cloud storage. (commit: a3dc381)",
        content: () => {
            return (
                <p>
                    In this version, we have improved the way cloud is connected. Now you can connect multiple different
                    accounts but the same type of cloud storage. You can also use a separate proxy server for each cloud
                    storage setup.
                </p>
            );
        }
    },
    {
        time: "11/20/2019",
        title: "StaticSync now supports Dropbox. (commit: 75411b8)",
        content: () => {
            return (
                <>
                    <p>
                        Now you can sync files with Dropbox. Free to download the 15-day trial version from out website.
                        If you have any suggestions, please send us an email.
                    </p>
                    <p>And also made improvements.</p>
                    <ul>
                        <li>The progress dialog no longer pops up when parsing the file.</li>
                        <li>
                            In the file tree of Project-window, if the filter option is turned on, the unrelated folder
                            will not be displayed.
                        </li>
                        <li>Fix the dead lock bug when user requests to stop syncing.</li>
                    </ul>
                </>
            );
        }
    },
    {
        time: "11/15/2019",
        title: "Use 'Ignore' instead of 'Do nothing'. (commit: bc0d96d)",
        content: () => {
            return (
                <p>
                    'Do nothing' should not be used as one of the options in conflict resolution. When the user does not
                    want to resolve a conflict or give up a copy behavior temporarily, the 'Ignore' option should be
                    used.
                </p>
            );
        }
    },
    {
        time: "11/14/2019",
        title: "StaticSync 1.1 has been released! (commit: 164b47a)",
        content: () => {
            return (
                <>
                    <p>Improve Project-inspector window.</p>
                    <ul>
                        <li>The files view is completely re-designed, now it is more easier to understand.</li>
                        <li>Add more icons to Project-inspector to enhance the usability.</li>
                        <li>Fix the 'auto check new version' option cannot be checked error.</li>
                    </ul>
                </>
            );
        }
    },
    {
        time: "11/8/2019",
        title: "Handle conflict actions (commit: 2ee1256)",
        content: () => {
            return (
                <ul>
                    <li>StaticSync will list all conflict actions and you need to select what to do before syncing.</li>
                    <li>Bug fix: The proxy editor is too small on MacOS.</li>
                    <li>Bug fix: Project settings editor layout is incorrect.</li>
                    <li>Bug fix: A crash after syncing is fixed.</li>
                </ul>
            );
        }
    },
    {
        time: "11/5/2019",
        title: "Add proxy support (commit: 78f1d58)",
        content: () => {
            return (
                <ul>
                    <li>Now you are able to use proxy server to access the network.</li>
                    <li>Bug fix: Now clear the history of a project is functional.</li>
                    <li>Improve the error messages when the syncing is failed</li>
                </ul>
            );
        }
    },
    {
        time: "11/1/2019",
        title: "Add a dialog to modify preferences (commit: 4d193b1)",
        content: () => {
            return (
                <>
                    <p>The options in preference dialog</p>
                    <ul>
                        <li>You can prevent the application checks update on every starts up</li>
                        <li>Now you can clear the logs those are recorded by StaticSync</li>
                        <li>Setup the timeout seconds for network jobs that remains responding from server</li>
                    </ul>
                </>
            );
        }
    },
    {
        time: "10/30/2019",
        title: "Improvements and fix bugs (commit: 5b60466)",
        content: () => {
            return (
                <ul>
                    <li>StaticSync will prompt user when receiving authorization information</li>
                    <li>Use text instead of icons for the status of each project</li>
                    <li>Fix a crash from quitting application when the status is 'Syncing' </li>
                    <li>Prevent user use duplicated name for a new project</li>
                </ul>
            );
        }
    },
    {
        time: "10/30/2019",
        title: "Fix an unusual crash (commit: 86ce87f)",
        content: () => {
            return (
                <p>
                    Sometimes the program will crash when the user is trying to authorize the privileges of OneDrive,
                    now the bug has been fixed.
                </p>
            );
        }
    },
    {
        time: "10/28/2019",
        title: "Fix bugs and add summary dialog (commit: a65d5ab)",
        content: () => {
            return (
                <ul>
                    <li>Fix a OneDrive access problem from Windows version</li>
                    <li>Improve the log timestamp by using ISO date format</li>
                    <li>Show a summary dialog after the synchronization is finished</li>
                </ul>
            );
        }
    },
    {
        time: "10/27/2019",
        title: "StaticSync 1.0 released (commit: 7ab772f)",
        content: () => {
            return (
                <p>
                    First version of StaticSync is released on both platforms: MacOS and Windows. Now you are able to
                    download the install package on your platform. The install package includes 30-day trial.
                </p>
            );
        }
    },
    {
        time: "8/18/2012",
        title: "Static Backup8 1.256 has been released",
        content: () => {
            return <p>Fixed the date time-stamp issue from output filename of scheduler program.</p>;
        }
    },
    {
        time: "8/11/2012",
        title: "Static Backup8 1.255 has been released",
        content: () => {
            return <p>Scheduler settings will be saved successfully after system reboot.</p>;
        }
    },
    {
        time: "4/14/2012",
        title: "Static Backup8 1.251 has been released",
        content: () => {
            return <p>Some improvements.</p>;
        }
    },
    {
        time: "4/10/2012",
        title: "Static Backup8 1.250 has been released",
        content: () => {
            return (
                <ul>
                    <li>Incremental backup is ready</li>
                    <li>Some bug has been fixed</li>
                </ul>
            );
        }
    },
    {
        time: "4/3/2012",
        title: "Static Backup8 1.248 has been released",
        content: () => {
            return (
                <ul>
                    <li>You can select files/registry items to restore</li>
                    <li>Some improvements</li>
                </ul>
            );
        }
    },
    {
        time: "2/25/2012",
        title: "Static Backup8 1.237 has been released",
        content: () => {
            return (
                <ul>
                    <li>Fixed bug from scheduler</li>
                    <li>You can select which files you want to restore before you starting restore action</li>
                    <li>Some improvements</li>
                </ul>
            );
        }
    },
    {
        time: "2/22/2012",
        title: "Backup8 1.235 has been released",
        content: () => {
            return (
                <ul>
                    <li>Scheduler has been included</li>
                    <li>Some improvents and bugs have been fixed</li>
                </ul>
            );
        }
    },
    {
        time: "2/8/2012",
        title: "Static Backup8 1.227 has been released",
        content: () => {
            return (
                <ul>
                    <li>Support Chrome personal data, plugins and settings</li>
                    <li>Support Safari personal data, plugins and settings</li>
                </ul>
            );
        }
    },
    {
        time: "2/6/2012",
        title: "Static Backup8 1.226 has been released",
        content: () => {
            return (
                <ul>
                    <li>All Backup8 products now will be installed to different folder in default</li>
                    <li>Fixed a bug while creating archive in some environment</li>
                </ul>
            );
        }
    },
    {
        time: "2/5/2012",
        title: "Static Backup8 1.224 has been released"
    },
    {
        time: "2/5/2012",
        title: "Windows Mail Backup8 1.224 has been released"
    },
    {
        time: "2/4/2012",
        title: "Thunderbird Backup8 1.223 has been released"
    },
    {
        time: "2/4/2012",
        title: "Windows Live Mail Backup8 1.223 has been released"
    },
    {
        time: "2/4/2012",
        title: "Outlook Backup8 1.223 has been released"
    },
    {
        time: "2/3/2012",
        title: "Firefox Backup8 1.223 has been released"
    },
    {
        time: "2/3/2012",
        title: "Static Backup8 1.223 has been released"
    },
    {
        time: "2/2/2012",
        title: "Incredimail Backup8 1.218 has been released"
    },
    {
        time: "2/1/2012",
        title: "Static Backup8 1.218 has been released"
    },
    {
        time: "1/25/2012",
        title: "Static Backup8 1.209 has been released",
        content: () => {
            return (
                <ul>
                    <li>You will be able to generate filename automatically</li>
                    <li>Some improvements and bugs are fixed</li>
                </ul>
            );
        }
    },
    {
        time: "1/22/2012",
        title: "Static Backup8 1.208 has been released"
    },
    {
        time: "1/20/2012",
        title: "Static Backup8 1.206 has been released",
        content: () => {
            return (
                <ul>
                    <li>Fix a bug that will cause the program crashes</li>
                    <li>Add Lua debugger and you can easy to build your own plugin</li>
                </ul>
            );
        }
    },
    {
        time: "1/17/2012",
        title: "Static Backup8 1.201 has been released",
        content: () => {
            return <p>Fix a bug that the error occurs when a directory exists from the restore destination.</p>;
        }
    },
    {
        time: "1/17/2012",
        title: "Static Backup8 1.199 has been released",
        content: () => {
            return (
                <ul>
                    <li>Archive Explorer now can be called from Backup8 main window</li>
                    <li>Fixed the memory issue when restoring large files from archive</li>
                    <li>Support Windows 7 progress-bar while backup or restore</li>
                    <li>Some improvements</li>
                </ul>
            );
        }
    },
    {
        time: "1/14/2012",
        title: "Static Backup8 1.197 has been released",
        content: () => {
            return (
                <ul>
                    <li>Fix a memory leak issue</li>
                    <li>Archive Explorer now can accept files from Windows Explorer</li>
                    <li>Now you can add custom registry items and folders</li>
                    <li>Crash report program has been added</li>
                    <li>Some other improvements and bug fix</li>
                </ul>
            );
        }
    },
    {
        time: "1/10/2012",
        title: "Static Backup8 1.185 has been released"
    },
    {
        time: "3/23/2010",
        title: "Static Email Backup series 2.9f have been released",
        content: () => {
            return (
                <ul>
                    <li>
                        New build includes crash dump program can help us tracing bug from user computer if program
                        failure
                    </li>
                    <li>Some other improvements and bug fix</li>
                </ul>
            );
        }
    },
    {
        time: "2/26/2009",
        title: "Static Email Backup series 2.9c have been released",
        content: () => {
            return (
                <p>
                    Save backup options after backup window close and load these options when backup window starts up.
                </p>
            );
        }
    },
    {
        time: "1/23/2009",
        title: "Static Email Backup series 2.9b have been released",
        content: () => {
            return <p>Program will not crash when open the profile manager after a custom folder has been added.</p>;
        }
    },
    {
        time: "1/11/2009",
        title: "Static Email Backup series 2.9a have been released",
        content: () => {
            return (
                <p>
                    In this version, you can indicate the backup items show or hide by clicking Select Profile button
                    from main-window.
                </p>
            );
        }
    },
    {
        time: "11/23/2008",
        title: "Static Email Backup Free Series 2.9b have been released",
        content: () => {
            return (
                <ul>
                    <li>These series will not have any limitation</li>
                    <li>Very easy to use, just one click to backup your email and settings</li>
                </ul>
            );
        }
    },
    {
        time: "11/21/2008",
        title: "Static Email Backup Series 2.9b have been released",
        content: () => {
            return <p>The backup or the restore program will prompt your if the email host software is not closed.</p>;
        }
    },
    {
        time: "11/15/2008",
        title: "Static Email Backup series 2.8a have been released",
        content: () => {
            return (
                <ul>
                    <li>New layout</li>
                    <li>Many improvements</li>
                </ul>
            );
        }
    },
    {
        time: "5/14/2008",
        title: "Static Eudora Backup has been released",
        content: () => {
            return (
                <ul>
                    <li>Backup email store from Eudora</li>
                    <li>Supports compression and encryption</li>
                    <li>
                        Can upload archive to FTP server or burn to CD/DVD writable device after backup process has
                        finished
                    </li>
                    <li>Scheduler has built-in</li>
                </ul>
            );
        }
    },
    {
        time: "5/11/2008",
        title: "Static Becky Backup has been released"
    },
    {
        time: "5/10/2008",
        title: "Static Email Backup series 2.6a have been released",
        content: () => {
            return <p>User can add custom folders to backup tasks.</p>;
        }
    },
    {
        time: "5/2/2008",
        title: "Static Email Backup 2.2e has been released"
    },
    {
        time: "2/20/2008",
        title: "Static Email Backup 2.2c has been released",
        content: () => {
            return <p>Improvements of scheduler.</p>;
        }
    },
    {
        time: "1/7/2008",
        title: "Static Email Backup series 2.2b have been released"
    },
    {
        time: "10/13/2007",
        title: "Static Email Backup 2.2a has been released",
        content: () => {
            return <p>You can backup email store from Windows Mail.</p>;
        }
    },
    {
        time: "9/6/2007",
        title: "Static Windows Mail Backup 2.1c has been released",
        content: () => {
            return (
                <ul>
                    <li>Backup email store from Windows Mail</li>
                    <li>You can compress and encrypt your archive</li>
                    <li>Transfer to FTP serve or burn to writable CD/DVD device</li>
                </ul>
            );
        }
    },
    {
        time: "6/10/2007",
        title: "Static Email Backup 2.1c has been released"
    },
    {
        time: "3/28/2007",
        title: "Static Email Backup 2.1a has been released",
        content: () => {
            return <p>You are able to backup email store from Thunderbird.</p>;
        }
    },
    {
        time: "1/4/2007",
        title: "Static Thunderbird Backup 2.1a has been released",
        content: () => {
            return (
                <ul>
                    <li>Backup email store from Thunderbird</li>
                    <li>You can compress and encrypt your archive</li>
                    <li>Transfer to FTP serve or burn to writable CD/DVD device</li>
                </ul>
            );
        }
    },
    {
        time: "11/9/2006",
        title: "Static Windows Live Mail Backup 2.0a has been released",
        content: () => {
            return (
                <ul>
                    <li>Backup email store from Window Live Mail</li>
                    <li>You can compress and encrypt your archive</li>
                    <li>Transfer to FTP server or burn to writable CD/DVD device</li>
                </ul>
            );
        }
    },
    {
        time: "9/1/2006",
        title: "Static Email Backup 2.0a has been released",
        content: () => {
            return (
                <ul>
                    <li>Backup email store from Outlook Express, Outlook, IncrediMail, Windows Live Mail</li>
                    <li>You can compress or encrypt archive</li>
                    <li>Transfer to FTP server or burn to writable CD/DVD device</li>
                </ul>
            );
        }
    },
    {
        time: "4/28/2006",
        title: "Static Outlook Express Backup 2.0a has been released"
    },
    {
        time: "3/5/2006",
        title: "Static Outlook Express Backup 1.9 has been released"
    },
    {
        time: "1/9/2006",
        title: "Static IncrediMail Backup 1.9 has been released"
    },
    {
        time: "11/28/2005",
        title: "Static IncrediMail Backup has been released"
    },
    {
        time: "10/2/2005",
        title: "Static Outlook Backup 1.8 has been released"
    },
    {
        time: "9/17/2005",
        title: "Static Outlook Backup 1.7 has been released"
    },
    {
        time: "7/20/2005",
        title: "Static Outlook Express Backup 1.7 has been released"
    },
    {
        time: "6/30/2005",
        title: "Static Outlook Express Backup 1.5 has been released"
    },
    {
        time: "6/19/2005",
        title: "Static Outlook Express Backup 1.4 has been released"
    },
    {
        time: "5/2/2005",
        title: "Static Outlook Express Backup 1.3 has been released"
    },
    {
        time: "4/10/2005",
        title: "Static Outlook Express Backup 1.2 has been released"
    },
    {
        time: "3/2/2005",
        title: "Static Outlook Express Backup 1.0 has been released"
    },
    {
        time: "2/28/2005",
        title: "StaticBackup Inc. up and running",
        content: () => {
            return <></>;
        }
    }
];

function renderContent(contentCallback) {
    if (contentCallback) {
        return contentCallback();
    } else {
        return <p>Some improvements and bugs have been fixed.</p>;
    }
}

function renderWhatsNewItem(item, key) {
    const { time, title, content } = item;
    return (
        <li key={key}>
            <span className="WhatsNew-head-title">
                <strong>{title}</strong> - {time}
            </span>
            {renderContent(content)}
        </li>
    );
}

function WhatsNew() {
    return (
        <div className="Section WhatsNew-bg">
            <div className="Content WhatsNew">
                <h1>What's new?</h1>
                <ul>
                    {whatsnewList.map((item, key) => {
                        return renderWhatsNewItem(item, key);
                    })}
                </ul>
            </div>
        </div>
    );
}

export default WhatsNew;
