import React from "react";
import "./OldProducts.scss";

function OldProducts() {
    return (
        <div className="Section OldProducts-bg">
            <div className="Content OldProducts">
                <h1>Maintenance of old products</h1>
                <p>For the previous email backup software developed by StaticBackup, if you have purchased a lifetime license, we will give you a free upgrade to Static Backup 8, please send us your registration number or order number to <a href="mailto:support@staticbackup.com">support@staticbackup.com</a>, and we will reply your message within 24 hours. The generated new authorization information will be delivered as soon as possible.</p>
            </div>
        </div>
    );
}

export default OldProducts;
