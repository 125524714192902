import React from "react";
import "./Blog.scss";
import { useParams } from "react-router-dom";

export default function Blog(props) {
    const { index } = useParams();
    const { blogs } = props;
    let blog;
    for (const b of blogs) {
        if (b.index === index) {
            blog = b;
        }
    }
    const { title, time, content } = blog;
    return (
        <div className="Section Blog-bg">
            <div className="Content Blog">
                <h1>{title}</h1>
                <p className="RedText">{time}</p>
                <div className="BlogContent">{content()}</div>
                <p>
                    <a href="/blog">Back to index</a>
                </p>
            </div>
        </div>
    );
}
