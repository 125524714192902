import React from "react";
import "./AboutHighlights.scss";

export default function AboutHighlights() {
    return (
        <div className="Section AboutHighlights-bg">
            <div className="Content AboutHighlights">
                <h1>What's important to us</h1>
                <div className="AboutHighlights-content">
                    <div className="AboutHighlights-highlight">
                        <h3>Simple and powerful</h3>
                        <p>
                            Simple in use and powerful in software functions, users do not need to understand the
                            concepts behind them, they just need to tell the program what to do.
                        </p>
                    </div>
                    <div className="AboutHighlights-highlight">
                        <h3>Delicate and fast</h3>
                        <p>
                            Try not to occupy the user's machine resources and respond to the user's operations quickly,
                            and on this basis, keep the software interface delicate and clean.
                        </p>
                    </div>
                    <div className="AboutHighlights-highlight">
                        <h3>Safety and security</h3>
                        <p>
                            Use standard encryption protocol for computer communication to maximize user privacy and
                            data security.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
