import React from "react";
import "./Downloads.scss";

import { ProductNameLine } from "./Common";

import {
    STATICSYNC_VER,
    DOWNLOAD_URL_MAC,
    DOWNLOAD_URL_WINDOWS,
    DOWNLOAD_URL_MAC_FREE,
    DOWNLOAD_URL_WINDOWS_FREE,
    DOWNLOAD_URL_BACKUP8,
    DOWNLOAD_URL_BACKUP9
} from "./Consts";

import Backup8Icon from "./backup8imgs/backup8.png";
import Backup9Icon from "./backup9imgs/Backup9.png";
import StaticSyncIcon from "./StaticSyncIcon.png";

import LogoMac from "./LogoMac.png";
import LogoWindows from "./LogoWindows.png";

import AppStoreLogo from "./AppStoreLogo.png";
import MicrosoftStoreLogo from "./MicrosoftStoreLogo.png";

const products = [
    {
        index: 0,
        name: "StaticSync",
        icon: StaticSyncIcon,
        version: STATICSYNC_VER,
        urlMore: "/staticsync",
        downloads: [
            {
                systemName: "Windows",
                logo: LogoWindows,
                url: DOWNLOAD_URL_WINDOWS,
                desc: "Download portable package for Windows",
                size: "37.3 MB",
                req: [
                    {
                        name: "System",
                        value: "Windows 7 64-bits or higher"
                    },
                    {
                        name: "RAM",
                        value: "256MB or more"
                    }
                ],
                store: {
                    image: MicrosoftStoreLogo,
                    name: "Microsoft Store",
                    url: "ms-windows-store://pdp/?ProductId=9N43733K03J1"
                },
                trial: "15-day trial"
            },
            {
                systemName: "macOS",
                logo: LogoMac,
                url: DOWNLOAD_URL_MAC,
                desc: "Download install package for macOS",
                size: "18.6 MB",
                trial: "15-day trial",
                req: [
                    {
                        name: "System",
                        value: "macOS Mojave or higher"
                    },
                    {
                        name: "RAM",
                        value: "256MB or more"
                    }
                ],
                store: {
                    image: AppStoreLogo,
                    name: "Apple Store",
                    url: "https://apps.apple.com/us/app/staticsync/id1489393897?ls=1&mt=12"
                }
            }
        ]
    },
    {
        index: 1,
        name: "StaticSync Free",
        icon: StaticSyncIcon,
        version: STATICSYNC_VER,
        urlMore: "/staticsync",
        downloads: [
            {
                systemName: "Windows",
                logo: LogoWindows,
                url: DOWNLOAD_URL_WINDOWS_FREE,
                desc: "Download portable package for Windows",
                size: "37.3 MB",
                trial: "Free",
                req: [
                    {
                        name: "System",
                        value: "Windows 7 64-bits or higher"
                    },
                    {
                        name: "RAM",
                        value: "256MB or more"
                    }
                ]
            },
            {
                systemName: "macOS",
                logo: LogoMac,
                url: DOWNLOAD_URL_MAC_FREE,
                desc: "Download install package for macOS",
                size: "18.6 MB",
                trial: "Free",
                req: [
                    {
                        name: "System",
                        value: "macOS Mojave or higher"
                    },
                    {
                        name: "RAM",
                        value: "256MB or more"
                    }
                ]
            }
        ]
    },
    {
        index: 2,
        name: "Backup8 & Backup9",
        // icon: Backup9Icon,
        // version: BACKUP9_VER,
        // urlMore: "/backup9",
        downloads: [
            {
                systemName: "Backup9",
                logo: Backup9Icon,
                url: DOWNLOAD_URL_BACKUP9,
                desc: "Download Backup9 for macOS",
                trial: "15-day trial",
                size: "28 MB",
                req: [
                    {
                        name: "System",
                        value: "macOS Mojave or higher"
                    },
                    {
                        name: "RAM",
                        value: "256MB or more"
                    }
                ]
            },
            {
                systemName: "Backup8",
                logo: Backup8Icon,
                url: DOWNLOAD_URL_BACKUP8,
                desc: "Download Backup8 for Windows",
                trial: "15-day trial",
                size: "8 MB",
                req: [
                    {
                        name: "System",
                        value: "Windows XP/Vista/7/8/10"
                    },
                    {
                        name: "RAM",
                        value: "256MB or more"
                    }
                ]
            }
        ]
    }
];

const oldProducts = [
    {
        name: "Static Restore Program",
        version: "2.9f",
        size: "3 MB",
        desc: "Restore backup archive data to your computer from Static backup programs.",
        downloadUrl: "https://files.staticbackup.com/static-restore.zip"
    },
    {
        name: "Static Email Backup",
        version: "2.9f",
        size: "3.7 MB",
        desc:
            "The backup program to backup Windows Mail, Windows Live Mail, Outlook, Outlook Express, Incredimail, Thunderbird, Becky and Eudora.",
        downloadUrl: "https://files.staticbackup.com/email-backup-install.exe"
    },
    {
        name: "Static Windows Mail Backup",
        version: "2.9f",
        size: "3.7 MB",
        desc: "The backup program to backup email data from Windows Mail.",
        downloadUrl: "https://files.staticbackup.com/windows-mail-backup-install.exe"
    },
    {
        name: "Static Windows Live Mail Backup",
        version: "2.9f",
        size: "3.7 MB",
        desc: "The backup program to backup email data from Windows Live Mail.",
        downloadUrl: "https://files.staticbackup.com/windows-live-mail-backup-install.exe"
    },
    {
        name: "Static Outlook Backup",
        version: "2.9f",
        size: "3.7 MB",
        desc: "The backup program to backup email data from Outlook.",
        downloadUrl: "https://files.staticbackup.com/outlook-backup-install.exe"
    },
    {
        name: "Static Outlook Express Backup",
        version: "2.9f",
        size: "3.7 MB",
        desc: "The backup program to backup email data from Outlook Express.",
        downloadUrl: "https://files.staticbackup.com/outlook-express-backup-install.exe"
    },
    {
        name: "Static IncrediMail Backup",
        version: "2.9f",
        size: "3.7 MB",
        desc: "The backup program to backup email data from Incredimail.",
        downloadUrl: "https://files.staticbackup.com/incredimail-backup-install.exe"
    },
    {
        name: "Static Thunderbird Backup",
        version: "2.9f",
        size: "3.7 MB",
        desc: "The backup program to backup email data from Thunderbird.",
        downloadUrl: "https://files.staticbackup.com/thunderbird-backup-install.exe"
    },
    {
        name: "Static Becky Backup",
        version: "2.9f",
        size: "3.7 MB",
        desc: "The backup program to backup email data from Becky.",
        downloadUrl: "https://files.staticbackup.com/becky-backup-install.exe"
    },
    {
        name: "Static Eudora Backup",
        version: "2.9f",
        size: "3.7 MB",
        desc: "The backup program to backup email data from Eudora.",
        downloadUrl: "https://files.staticbackup.com/eudora-backup-install.exe"
    }
];

function renderDownloadReq(reqItem, key) {
    const { name, value } = reqItem;
    return (
        <li key={key}>
            <strong>{name}</strong> - {value}
        </li>
    );
}

function renderDownloadFromStore(store) {
    if (store) {
        const { image, name, url } = store;
        return (
            <a href={url}>
                <img className="StoreImage" src={image} alt={name} />
            </a>
        );
    } else {
        return <></>;
    }
}

function renderDownloadRect(downloadItem, clsNameOfRect, key) {
    const { systemName, logo, req, url, desc, size, trial, store } = downloadItem;
    return (
        <div key={key} className={clsNameOfRect}>
            <div className="Products-item-operating-system-title">
                <img className="SystemLogo" src={logo} alt={systemName} />
                <strong>{systemName}</strong>
            </div>
            <ul>
                {req.map((reqItem, key) => {
                    return renderDownloadReq(reqItem, key);
                })}
            </ul>
            <div className="CenterText">
                <h3>
                    Package size: {size}
                    <br />
                    {trial}
                </h3>
                <p>
                    <a href={url}>{desc}</a>
                </p>
                <p>{renderDownloadFromStore(store)}</p>
            </div>
        </div>
    );
}

function DownloadItem(props) {
    const { index, name, icon, version, urlMore, downloads } = props;
    const clsName = index % 2 === 0 ? "Products-item1" : "Products-item2";
    const clsNameOfRect = index % 2 === 0 ? "Products-item-download-rect1" : "Products-item-download-rect2";
    return (
        <div key={index} className={clsName}>
            <ProductNameLine icon={icon} name={name} version={version} urlMore={urlMore} />
            <div className="Products-item-line">
                {downloads.map((downloadItem, key) => {
                    return renderDownloadRect(downloadItem, clsNameOfRect, key);
                })}
            </div>
        </div>
    );
}

function MoreDownloadItem(item, key) {
    const { name, version, size, downloadUrl, desc } = item;
    const clsName = key % 2 === 0 ? "Downloads-more-product-list-item1" : "Downloads-more-product-list-item2";
    return (
        <div className={clsName}>
            <div className="Downloads-more-product-title">
                <strong>
                    {name}
                    <small> - {version}</small>
                </strong>
                <br />
                {desc}
            </div>
            <div className="Downloads-more-product-size-download">
                <strong>{size} </strong>
                <span>
                    <a href={downloadUrl}>Download install package</a>
                </span>
            </div>
        </div>
    );
}

function Downloads() {
    return (
        <div className="Section Downloads-bg">
            <div className="Content Downloads">
                <h1>Downloads</h1>
                {products.map(product => {
                    return DownloadItem(product);
                })}
                <br />
                <br />
                <h1>Outdated software downloads</h1>
                <p>
                    These download packages are <strong className="Downloads-warning">NO LONGER</strong> recommended for
                    new users. If you need to back up your email data, please use <a href="/backup8">Backup8</a>. If you
                    have purchased the following products, we strongly recommend that you upgrade to Backup8, now we
                    provide <strong className="Downloads-warning">FREE UPGRADES</strong> for those who already purchased
                    Static Email Backup program. Please send us Send an email with your purchase name, email address and
                    order number, and we will send you a Backup8 registration code in a short time.
                </p>
                <p>
                    {oldProducts.map((product, key) => {
                        return MoreDownloadItem(product, key);
                    })}
                </p>
            </div>
        </div>
    );
}

export default Downloads;
