import React from "react";
import "./FeatureIcon.scss"

function FeatureIcon(props) {
    return (
        <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27"><defs></defs><g id="layer" data-name="layer"><g id="Feature"><circle className="cls-1" cx="13.5" cy="13.5" r="8.69" /><polyline className="cls-1" points="7.57 12.17 10.29 17.28 19.43 9.72" /><rect className="cls-2" width="27" height="27" /></g></g></svg>
    );
}

export default FeatureIcon;
