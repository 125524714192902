import React from "react";

import { SupportArea } from "./Common";

function Backup8Document() {
    return (
        <SupportArea urlHelp="/backup8/help" urlFAQ="/backup8/faq" />
    );
}

export default Backup8Document;
