import React from "react";
import "./FAQ.scss";

function Backup8FAQ() {
    return (
        <div className="Section FAQ-bg">
            <div className="Content FAQ">
                <h1>Frequently asked questions (Static Backup8)</h1>
                <ol>
                    <li>
                        <h2>Why I cannot register my product?</h2>
                        <p>Please following these steps to register the product:</p>
                        <ul>
                            <li>Please make sure you have downloaded the correct installation package from our website, Click here to visit the download page.</li>
                            <li>Double-click the installation package from your download folder. You will be prompted if your operating system is Windows Vista/7 and UAC is opened, please allow.</li>
                            <li>If your operating system is Windows Vista/7, please run the program as administrator by right clicking on the main program icon at first time.</li>
                            <li>Find your registration information from your order notice email, and please use CTRL+C and CTRL+V to copy the register-name and register-code from your email message.</li>
                            <li>The program will prompt you registration information is correct if you do everything right.</li>
                            <li>After you doing these steps, you can run the program in normal mode, and do not need administrator privileges on Windows Vista/7.</li>
                        </ul>
                    </li>
                    <li>
                        <h2>Is your online order secure?</h2>
                        <p>Yes, all online purchases are guaranteed 100% secure. Your ordering information will never be shared with anyone.</p>
                    </li>
                    <li>
                        <h2>Do I have pay for the upgrades?</h2>
                        <p>No! All future upgrades are always free to registered users! You do not have to pay again. You may install multiple copies of the program as long as only the specified number of copies is in use at one time. I.E. on a single license, you may install at work, at home and on laptop as long as only a single copy of the software is in use at one time.</p>
                    </li>
                    <li>
                        <h2>Do you provide free technical support to your products?</h2>
                        <p>Yes, we provide free technical support to our users and we will respond to all technical support emails in 24 hours, please note registered user's emails always get priority.</p>
                    </li>
                    <li>
                        <h2>I have both a desktop computer and laptop, do I need purchase two license for your product?</h2>
                        <p>No! On a single-user license, you may install at work, at home and on laptop as long as only a single copy of the software is in use at one time.</p>
                    </li>
                    <li>
                        <h2>Do you provide volume discount or educational license?</h2>
                        <p>Yes, please contact <a href="mailto:marketing@staticbackup.com">marketing@staticbackup.com</a> for details.</p>
                    </li>
                    <li>
                        <h2>I don't have a credit card, where else can I buy it?</h2>
                        <p>Please visit <a href="https://www.staticbackup.com/purchase">https://www.staticbackup.com/purchase</a> for instructions for payment via Check/Money order.</p>
                    </li>
                    <li>
                        <h2>I registered the product several months ago, how do I get the latest version?</h2>
                        <p>You can download the latest version of products from <a href="https://www.staticbackup.com/download">https://www.staticbackup.com/download</a>, and use your existing registration code to activate the license on the new version. Please uninstall all previous versions before installing an update.</p>
                    </li>
                    <li>
                        <h2>How to get support?</h2>
                        <p>If your problem isn't in the list, please send your problem to <a href="mailto:support@staticbackup.com">support@staticbackup.com</a></p>
                    </li>
                </ol>
            </div>
        </div>
    );
}

export default Backup8FAQ;
