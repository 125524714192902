import React, { useState } from "react";
import "./Footer.scss";

import Logo from "./Logo.png";

import axios from "axios";

import Button from "./Button";

import "./MenuItem.scss";

const SUBSCRIBE_URL = "https://api.staticbackup.com/mailinglist/subscribe";

const DEFAULT_MESSAGE = "";

function Footer() {
    const [clsName, setClsName] = useState("EmailSubscribe-inputs");
    const [email, setEmail] = useState("");
    const [enable, setEnable] = useState(true);
    const [buttonType, setButtonType] = useState("Gray");
    const [message, setMessage] = useState(DEFAULT_MESSAGE);
    const onEmailChanged = e => {
        setClsName("EmailSubscribe-inputs");
        const curr = e.target.value;
        setEmail(curr);
        setMessage(DEFAULT_MESSAGE);
        setButtonType(curr.length === 0 ? "Gray" : "Normal");
    };
    const subscribe = async e => {
        if (!enable || email.length === 0) {
            return;
        }
        e.preventDefault();
        setButtonType("Gray");
        setEnable(false);
        const res = await axios.post(SUBSCRIBE_URL, { email });
        setButtonType("Normal");
        setEnable(true);
        const { succ } = res.data;
        if (succ) {
            setEmail("");
            setMessage("Thank you! We have received your email address.");
        } else {
            // Set warning background for the input
            setClsName("EmailSubscribe-inputs-red");
            setMessage("Invalid email address or it has already been subscribed!");
        }
    };
    return (
        <div className="Section">
            <div className="Content Footer">
                <div className="Footer-map">
                    <div className="Footer-copy">
                        <img className="Footer-logo" src={Logo} alt="Logo" />
                    </div>
                    <div className="Footer-map-column">
                        <h1>PRODUCTS</h1>
                        <a className="Footer-link" href="/staticsync">
                            StaticSync
                        </a>
                        <a className="Footer-link" href="/backup8">
                            Backup 8
                        </a>
                    </div>
                    <div className="Footer-map-column">
                        <h1>STATICBACKUP</h1>
                        <a className="Footer-link" href="/whatsnew">
                            What's new?
                        </a>
                        <a className="Footer-link" href="/products">
                            Products
                        </a>
                        <a className="Footer-link" href="/downloads">
                            Download
                        </a>
                        <a className="Footer-link" href="/purchase">
                            Purchase
                        </a>
                        <a className="Footer-link" href="/support">
                            Support
                        </a>
                    </div>
                    <div className="Footer-map-column">
                        <h1>COMPANY</h1>
                        <a className="Footer-link" href="/blog">
                            Blog
                        </a>
                        <a className="Footer-link" href="/about">
                            About Us
                        </a>
                        <a className="Footer-link" href="/terms">
                            Terms
                        </a>
                        <a className="Footer-link" href="/privacy">
                            Privacy
                        </a>
                        <a className="Footer-link" href="https://twitter.com/staticbackup">
                            Twitter
                        </a>
                    </div>
                    <div className="Footer-map-column">
                        <h1>SUBSCRIBE TO MAILING-LIST</h1>
                        <p className="EmailSubscribe-desc">
                            After joining our mailing-list, you will receive notifications of our new products,
                            discounts and other offers.
                        </p>
                        <div className={clsName}>
                            <input value={email} placeholder="Enter an email address here" onChange={onEmailChanged} />
                            <Button type={buttonType} caption="Go" onClick={subscribe} />
                        </div>
                        <div className="EmailSubscribe-notification">&nbsp;{message}</div>
                        <p>&copy; 2005-2020 StaticBackup Inc.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
