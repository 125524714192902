import React, { useState } from "react";
import { useParams } from "react-router-dom";
import "./DownloadAction.scss";

import { operatingSystemName, isMacOS, getDownloadUrl } from "./Consts";

function DownloadAction() {
    const { name } = useParams();
    const sysName = isMacOS() ? "macOS" : "Windows";

    const [seconds, setSeconds] = useState(3);
    const downloadUrl = getDownloadUrl(name, sysName);
    const packageSysName = operatingSystemName();

    const timeoutHandler = () => {
        if (seconds === 1) {
            window.gtag('event', 'conversion', { 'send_to': 'AW-1071197972/sAADCOCWxrMBEJTe5P4D' });
            window.location.href = downloadUrl;
        } else {
            setSeconds(seconds - 1);
        }
    };
    setTimeout(timeoutHandler, 1000);
    return (
        <div className="Section DownloadAction-bg">
            <div className="Content DownloadAction">
                <div className="SmallTitle-gray">Downloading {name}</div>
                <p>
                    The install package for <strong>{packageSysName}</strong> is prepared and will start the download
                    after {seconds} seconds... If the download didn't start, please click&nbsp;
                    <a href={downloadUrl}>here</a> to download it manually.
                </p>
            </div>
        </div>
    );
}

export default DownloadAction;
