import React from "react";
import { useParams } from "react-router-dom";
import "./Help.scss";

function renderByTopic(topics, topic) {
    if (!topic) {
        return (
            topics[0].content()
        );
    }
    return topics.map((loopTopic) => {
        if (loopTopic.topic === topic) {
            return (
                <div key={Math.random()}>
                    {loopTopic.content()}
                </div>
            );
        }
        return <div key={Math.random()}></div>;
    });
}

export function Help(props) {
    const { topics, sidebar } = props;
    const { topic } = useParams();
    const content = renderByTopic(topics, topic);
    return (<div className="Section Help-bg">
        <div className="Content Help">
            {sidebar()}
            <div className="Help-content">
                {content}
            </div>
        </div>
    </div>);
}

export default Help;
