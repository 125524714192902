import React from "react";
import "./Backup9Desc.scss";

import FeatureIcon from "./FeatureIcon";

function Backup9Desc() {
    return (
        <div className="Section Backup9Desc-bg">
            <div className="Content Backup9Desc">
                <h1>What can Backup9 do?</h1>
                <p>
                    Every day we use computers to produce a large amount of data, which should belong to you personally,
                    but is stored in the database of large companies. We provide another method to save your personal data.
                    Using Backup9 will effectively back up your personal data to a location, such as another
                    hard disk or your cloud storage. Backup9 uses multi-threading technology, strong encryption and
                    compression to protect and store your data. You can access and restore them anytime, anywhere. At
                    the same time Backup9 also provides a Scheduler to automatically run a backup plan.
                </p>
                <div className="Backup9Desc-features">
                    <div className="Backup9Desc-feature-entry">
                        <div className="Backup9Desc-feature-entry-title">
                            <FeatureIcon />
                            <h3>Easy to use</h3>
                        </div>
                        <p>
                            After starting Backup9, all backupable items and data size will be listed. You just need to
                            click the Backup icon and adjust the settings. After tapping confirm, you will get a backup
                            archive that stores all the backups.
                        </p>
                    </div>
                    <div className="Backup9Desc-feature-entry">
                        <div className="Backup9Desc-feature-entry-title">
                            <FeatureIcon />
                            <h3>Strong encryption and compression functions</h3>
                        </div>
                        <p>
                            Backup9 uses AES encryption algorithm to encrypt your data. You must remember the password
                            you set or your data will not be recovered. This mechanism will maximize your data security
                            and privacy. At the same time Backup9 uses zlib to compress your data to minimize the space
                            used by your backup archive.
                        </p>
                    </div>
                    <div className="Backup9Desc-feature-entry">
                        <div className="Backup9Desc-feature-entry-title">
                            <FeatureIcon />
                            <h3>Use Scheduler to execute your backup plan</h3>
                        </div>
                        <p>
                            Backup9 provides a Scheduler to perform your backup. You only need to set up your backup
                            content, you can completely forget about backup. Scheduler will dutifully back up your data
                            according to the plan.
                        </p>
                    </div>
                    <div className="Backup9Desc-feature-entry">
                        <div className="Backup9Desc-feature-entry-title">
                            <FeatureIcon />
                            <h3>Backup to cloud storage</h3>
                        </div>
                        <p>
                            Backup9 supports cloud storage. You can choose to upload the backup archive to your cloud
                            storage at the same time after the backup is completed. This way, even if your local hard
                            disk is damaged, you can still ensure that your data is secure to the greatest extent.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Backup9Desc;
