import React from "react";

export const Jan_17_2020 = {
    index: "Jan_17_2020",
    title: "Welcome to StaticBackup blog!",
    time: "JAN 17",
    brief: "We have added a blog for StaticBackup Inc., new status about adding support for Amazon Drive and more.",
    content: () => {
        return (
            <>
                <p>
                    We have updated the website of StaticBackup Inc. and added a blog system. In the future, we will
                    report any plans or progress to you. We also listen to your suggestions sincerely.
                </p>
                <p>
                    Recently, we were busy adding support for Amazon Drive to StaticSync, but when the code was ready
                    for testing, we found that Amazon Drive no longer supports new third-party developers, which is very
                    frustrating. So we plan to add support for WebDAV and Synology in the future.
                </p>
                <p>
                    We have also recently fixed a few minor issues (Commit: b41c10a), a new version has been released.
                    Specific updates can be found from <a href="/whatsnew">What's New?</a>.
                </p>
            </>
        );
    }
};
