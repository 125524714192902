import React from "react";
import "./BlogIndex.scss";

function renderBlog(blog) {
    const { title, time, index, brief } = blog;
    const uri = "/blog/" + index;
    return (
        <div className="BlogPanel">
            <a href={uri}>
                <div className="BlogHead">
                    <h3 className="BlogTitle">{title}</h3>
                    <div className="BlogBrief">{brief}</div>
                </div>
                <div className="BlogFooter">
                    <span>READ MORE &nbsp;&nbsp;></span>
                    <div className="BlogTime">{time}</div>
                </div>
            </a>
        </div>
    );
}

export default function BlogIndex(props) {
    const { blogs } = props;
    return (
        <div className="Section BlogIndex-bg">
            <div className="Content BlogIndex">
                <h1>Blog</h1>
                <div className="BlogList">
                    {blogs.map((blog, key) => {
                        return (
                            <div key={key} className="BlogRow">
                                {renderBlog(blog)}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
