import React from "react";
import "./MenuItem.scss";

function MenuItem(props) {
    return (
        <a className="MenuItem" href={props.href}>
            {props.caption}
        </a>
    );
}

export default MenuItem;
