import React from "react";
import { Link } from "react-router-dom";

import Help from "./Help";

function Sidebar() {
    return (
        <div className="Help-sidebar">
            <h1>Introduction</h1>
            <p><Link to="/backup8/help/what-is-backup8">What is Backup8?</Link></p>
            <p><Link to="/backup8/help/how-does-it-work">How does Backup8 work?</Link></p>
            <p><Link to="/backup8/help/review-from-bitsdujour">Review from BitsDujour</Link></p>
            <h1>Start backup</h1>
            <p><Link to="/backup8/help/download-and-install">Download and install</Link></p>
            <p><Link to="/backup8/help/start-backup8">Start Backup8</Link></p>
            <p><Link to="/backup8/help/associate-filetype">Associate file-type</Link></p>
            <p><Link to="/backup8/help/view-my-personal-data">View my personal data</Link></p>
            <p><Link to="/backup8/help/add-custom-files-and-reg-items">Add custom entries</Link></p>
            <p><Link to="/backup8/help/run-my-first-backup">Run my first backup</Link></p>
            <p><Link to="/backup8/help/decide-the-archive-name">Decide the archive name</Link></p>
            <p><Link to="/backup8/help/schedule-backup">Schedule backup</Link></p>
            <p><Link to="/backup8/help/backup-save-log-file">Save log file</Link></p>
            <h1>Start to restore</h1>
            <p><Link to="/backup8/help/open-an-archive-file">Open an archive file</Link></p>
            <p><Link to="/backup8/help/view-all-items">View all items</Link></p>
            <p><Link to="/backup8/help/restore-all-items">Restore all items</Link></p>
            <p><Link to="/backup8/help/restore-custom-items">Restore custom items</Link></p>
            <p><Link to="/backup8/help/restore-save-log-file">Save log file</Link></p>
            <h1>Registration</h1>
            <p><Link to="/backup8/help/end-user-license-agreement">End-user license agreement</Link></p>
            <p><Link to="/backup8/help/enter-register-name-key">Enter register name/key</Link></p>
            <h1>Having trouble?</h1>
            <p><Link to="/backup8/help/crash-report">Crash report</Link></p>
            <p><Link to="/backup8/help/log-report">Log report</Link></p>
        </div>
    )
}

const topics = [
    {
        topic: "what-is-backup8",
        content: () => {
            return (
                <>
                    <h1>What is Backup8?</h1>
                    <p>When you are browsing website, your browser will save cookies, history, and your password. You will add bookmarks or install plugins to your web browser such as Firefox, Chrome, Safari. When you are using mail-client software to receive email messages, those messages will store on your computer. When you using Microsoft Office, you will save your files to My Documents folder. All those data are very important, keep them safe to prevent hard-driver fail, system crashes or other unknown reason to lose your important data. Backup8 is the powerful backup software and it will backup all your personal data from your computer to one archive file with high ratio compression. You can move it to network, flash disk or write to disc to prevent data lost.</p>
                    <p>Backup8 is very easy to use. And it also can add custom folders/files or registry items. And it also support incremental backup. Restore procedure is easy too. You can restore custom files/registry items from your archive file. Scheduler has built-in.</p>
                    <h1>Backup8 supports these applications</h1>
                    <ul>
                        <li>Internet Explorer</li>
                        <li>Chrome</li>
                        <li>Firefox</li>
                        <li>Safari</li>
                        <li>Outlook Express</li>
                        <li>Windows Mail</li>
                        <li>Windows Live Mail</li>
                        <li>Outlook</li>
                        <li>Incredimail</li>
                        <li>Thunderbird</li>
                        <li>Foxmail</li>
                        <li>Thebat</li>
                        <li>Becky</li>
                    </ul>
                </>
            );
        }
    },
    {
        topic: "how-does-it-work",
        content: () => {
            return (
                <>
                    <h1>How does Backup8 work?</h1>
                    <p>Backup8 will backup all personal data into one archive file. You can move this file to network, ftp server or burn to cd/dvd disc. You can start backup procedure manually by using backup wizard or you also can use scheduler to arrange the backup procedure and you don't need to take care of it. Backup8 can also backup only those changed files, new files from your computer to save your backup time.</p>
                </>
            );
        }
    },
    {
        topic: "review-from-bitsdujour",
        content: () => {
            return (
                <>
                    <h1>Review from BitsDujour</h1>
                    <p>What would you do if you lost all of your email messages? Or all of your internet browser settings? So many times, we become so comfortable with the technology that we use everyday, that we forget that we're just a single power surge away from disaster! Instead of messing around with overly complex backup systems that make copies of every little file, just stick with Static Backup8 to safeguard what's most important to you! It's today's discount software promotion, available at a reduced price for a limited time!</p>
                    <p>Static Backup8 is the easiest way to back up your email messages, browser settings, and documents, ensuring that you'll be up and running quickly in the event of a data loss. With Static Backup8, all of your major players are covered - Windows Live Mail, Windows Mail, Outlook Express, Thunderbirk, Incredimail, and more are all fully compatible with this amazing backup program. Plus, you'll be able to use Static Backup8 to back up all settings and favorites from Internet Explorer and Firefox, plus the contents of your My Documents folder. You'll never need to worry about backup space, because Static Backup8 very kindly compresses all output. There's even support for archive files larger than 4GB!</p>
                    <p>Even if you're not technically inclined, Static Backup8 is easy to use, thanks to wizards that hold your hand as they guide you through the process of getting stuff backed up. The amount of data that you back up with Static Backup8 is fully customizable, so you can choose to back up fewer items if you want. Restoring your data is just as easy, all it takes is just a few clicks!</p>
                </>
            );
        }
    },
    {
        topic: "download-and-install",
        content: () => {
            return (
                <>
                    <h1>Download and install</h1>
                    <p>StaticBackup Inc. provides 20-day free trial version of Backup8. You can download Backup8 for free and try before you buy. To download install package, please click the download url <a href="https://www.staticbackup.com/backup8/download">https://www.staticbackup.com/backup8/download</a> and the download process will start soon. After download completed, please double-click on the install package and the installation wizard will guide you to install the program step by step.</p>
                </>
            );
        }
    },
    {
        topic: "start-backup8",
        content: () => {
            return (
                <>
                    <h1>Start Backup8</h1>
                    <p>After installation, you can find Backup8 main-program icon from Start-menu, or you can find it from your desktop if you allow the setup program create icon to your desktop. Double-click on the icon, and Backup8 will start soon.</p>
                </>
            );
        }
    },
    {
        topic: "associate-filetype",
        content: () => {
            return (
                <>
                    <h1>Associate file-type</h1>
                    <p>On the first-time Backup8 starts up. Program will prompt you for administrator privileges. Because Backup8 will associate .bk file to Archive Explorer to allow you double-click .bk file from Windows Explorer to start Restore wizard. You can right-click on Backup8 icon and select Run as administrator to let Backup8 get the privileges on first-time starts up. You only need to do this one time.</p>
                </>
            )
        }
    },
    {
        topic: "view-my-personal-data",
        content: () => {
            return (
                <>
                    <h1>View my personal data</h1>
                    <p>Backup8 will scan your computer and calculate total bytes, registry items/files which have found from your computer from different application. In main-window of Backup8, you can find the application icon with a short description, and the total found bytes follows. On the right of the application line, you can view how many files/registry items have been found. Scroll down to view more items.</p>
                    <h1>Change profiles to hide application</h1>
                    <p>Yes, I know you don't need all applications to be backed up, please click 'Profiles' button on main-window to uncheck those application items and they will not list on main-window anymore.</p>
                </>
            );
        }
    },
    {
        topic: "add-custom-files-and-reg-items",
        content: () => {
            return (
                <>
                    <h1>Add custom files and registry items</h1>
                    <p>If you have your personal data which are not listed on the main-window, you can add it. Include files and registry items. Please click 'Custom Items' button on main-window and you will find a dialog window popups up. You can add registry path, files and paths to lists click Ok button to save these custom-items. And you are able to backup these custom items from backup wizard or backup scheduler.</p>
                </>
            );
        }
    },
    {
        topic: "run-my-first-backup",
        content: () => {
            return (
                <>
                    <h1>Run my first backup</h1>
                    <p>Before you start backup wizard, please make sure you have closed all those application which have contain the data you want to backup. Such as Firefox, IE, Safari, Thunderbird and etc. Before the backup program will not able to read necessary files if these application are running.</p>
                    <p>From the main-window of Backup8, you can click 'Backup' button to start backup wizard. Backup wizard will prompt you to select which application data you want to backup on the first step. Click on next button when you have done the selection.</p>
                    <p>On the second step, you can choose to make an incremental backup or make a full backup. If you are making a full backup, please click 'New backup' radio button, otherwise, please click 'Incremental backup' to backup those only changed files.</p>
                    <h1>New backup</h1>
                    <p>Make a new full backup means the backup program read and compress all files/registry items which have found from your computer to an archive file. The backup program can generate an output archive filename from current date/time for you, please view <a href="/backup8/help/decide-the-archive-name">'Let the program decides the archive name'</a></p>
                    <h1>Incremental backup</h1>
                    <p>If you already have an archive file and only want to backup those changed files, new files from your computer. Please choose Incremental backup. The backup program will compare each file and only backup those files which are modified or not exists to your archive. If the archive file doesn't exist, the backup program will make a full backup and store to the output archive file.</p>
                </>
            );
        }
    },
    {
        topic: "decide-the-archive-name",
        content: () => {
            return (
                <>
                    <h1>Let the program decides the archive name</h1>
                    <p>Backup program can generate an output archive filename from current date/time for you. In the backup step 2, you are able to find a check-box named 'Generate filename automatically', if you want to allow the backup program decides the output archive filename, please click on the option to check it. and fill the following options. You only need fill all those options once, after you click Next button, these options will be saved and load on next backup automatically.</p>
                    <h1>Why?</h1>
                    <p>Because it will make life more easier. You don't need to enter or choose the output filename on every backup time, just click Next button and wait.</p>
                </>
            );
        }
    },
    {
        topic: "schedule-backup",
        content: () => {
            return (
                <>
                    <h1>Schedule backup</h1>
                    <p>You can setup scheduler for your backup. Please click Scheduler button from main-window to open the scheduler setup dialog. Two pages will be found from scheduler setup dialog: Time, Items</p>
                    <p>Time page allows you to enable or disable scheduler. And you can set when the backup program starts up, you also need to setup the output folder or output archive filename, that depends you want to make new backup or incremental backup.</p>
                    <ul>
                        <li>
                            <h2>Start time</h2>
                            <p>
                                Fill mm:ss format in the start-time edit-box, this time will indicate when the backup program starts up in a day. For example, if you have filled 14:07 to the edit-box, the scheduler type is choosed Hourly and every 1 hour, the backup program will start on 14:07, 15:07, 16:07 and etc.
                            </p>
                        </li>
                        <li>
                            <h2>Hourly</h2>
                            <p>Every few hours to run the backup program</p>
                        </li>
                        <li>
                            <h2>Daily</h2>
                            <p>Every few days to run the backup program</p>
                        </li>
                        <li>
                            <h2>Weekly</h2>
                            <p>You can select one or more days of week to indicate when the backup program start</p>
                        </li>
                        <li>
                            <h2>Monthly</h2>
                            <p>You can add days to the list, and all these days will start backup program</p>
                        </li>
                        <li>
                            <h2>Output</h2>
                            <p>If you have not checked Incremental backup, please input or choose an output folder for the place of archive file. Because the output filename will be generated from current date/time automatically. If you have checked Incremental backup, please enter or choose an output archive filename. Backup program will start a full backup once and doing incremental backup next.</p>
                        </li>
                    </ul>
                    <p>Items page allows you to add backup items which you want to backup, click Add button you will find a 'Add items' dialog, and you are able to add items. Check or uncheck those items from 'Add items' dialog and click Ok button to add to scheduler. If the items already exists, scheduler will not add them without prompt.</p>
                    <h1>Service application for Backup8 scheduler</h1>
                    <p>After you click 'Ok' button, all options will be stored to scheduler service. Please make sure 'Backup8 Scheduler' service is running, otherwise you will be noticed when the scheduler settings dialog open. You can find the service program from Control-panel.</p>
                </>
            )
        }
    },
    {
        topic: "backup-save-log-file",
        content: () => {
            return (
                <>
                    <h1>Save log file</h1>
                    <p>After backup program finish, you can save the backup log file and send it to us if you have any question about your backup program. Scheduler backup will also save backup log file with the archive file.</p>
                </>
            )
        }
    },
    {
        topic: "open-an-archive-file",
        content: () => {
            return (
                <>
                    <h1>Open an archive file</h1>
                    <p>If you want to restore your archive, you need first to open it by using Archive Explorer.</p>
                    <ul>
                        <li>You can double-click on your archive file from Windows Explorer to open it</li>
                        <li>You can open Archive Explorer and select menu File->Open to open your archive file</li>
                        <li>You can open Archive Explorer and drag-drop archive file from Windows Explorer</li>
                    </ul>
                    <p>You need few seconds to open your archive, after your archive opened you are able to restore your files/registry items.</p>
                </>
            );
        }
    },
    {
        topic: "view-all-items",
        content: () => {
            return (
                <>
                    <h1>View all items</h1>
                    <p>After archive has been opened by Archive Explorer, you can view all files/registry items from the main-window. You can also adjust the info-pane to select profile to filter. You can click 'Select all' button to select all files/registry items which are listed on main-window, or you can check/uncheck one or more custom items.</p>
                </>
            );
        }
    },
    {
        topic: "restore-all-items",
        content: () => {
            return (
                <>
                    <h1>Restore all items</h1>
                    <p>Click third button on top of main-window to open restore wizard. In the first step of restore wizard, you need to confirm which data of applications you want to restore. Check or uncheck items to prevent restore program to restore unwant data. And click Next button to start restore.</p>
                </>
            );
        }
    },
    {
        topic: "restore-custom-items",
        content: () => {
            return (
                <>
                    <h1>Restore custom items</h1>
                    <p>You can select custom files/registry items from current main-window list and restore those selected files/registry items only. Select your items and click the second button on top tool-bar of main-window, on 'Confirm restore procedure' dialog, please click Restore button to restore selected items.</p>
                </>
            );
        }
    },
    {
        topic: "restore-save-log-file",
        content: () => {
            return (
                <>
                    <h1>Save log file</h1>
                    <p>You can always find 'Save log' button on each restore window/wizard, if you have any question about the restore program, please save the log file and send to us.</p>
                </>
            );
        }
    },
    {
        topic: "end-user-license-agreement",
        content: () => {
            return (
                <>
                    <h1>Software License Agreement</h1>
                    <ol>
                        <li>This is an agreement between Licensor and Licensee, who is being licensed to use Backup8.</li>
                        <li>Licensee acknowledges that this is only a limited nonexclusive license. Licensor is and remains the owner of all titles, rights, and interests in the Software.</li>
                        <li>This License permits Licensee to install the Software on more than one computer system, as long as the Software will not be used on more than one computer system simultaneously. Licensee will not make copies of the Software or allow copies of the Software to be made by others, unless authorized by this License Agreement. Licensee may make copies of the Software for backup purposes only.</li>
                        <li>This Software is subject to a limited warranty. Licensor warrants to Licensee that the physical medium on which this Software is distributed is free from defects in materials and workmanship under normal use, the Software will perform according to its printed documentation, and to the best of Licensor's knowledge Licensee's use of this Software according to the printed documentation is not an infringement of any third party's intellectual property rights. This limited warranty lasts for a period of 20 days after delivery. To the extent permitted by law, THE ABOVE-STATED LIMITED WARRANTY REPLACES ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, AND LICENSOR DISCLAIMS ALL IMPLIED WARRANTIES INCLUDING ANY IMPLIED WARRANTY OF TITLE, MERCHANTABILITY, NONINFRINGEMENT, OR OF FITNESS FOR A PARTICULAR PURPOSE. No agent of Licensor is authorized to make any other warranties or to modify this limited warranty. Any action for breach of this limited warranty must be commenced within one year of the expiration of the warranty. Because some jurisdictions do not allow any limit on the length of an implied warranty, the above limitation may not apply to this Licensee. If the law does not allow disclaimer of implied warranties, then any implied warranty is limited to 20 days after delivery of the Software to Licensee. Licensee has specific legal rights pursuant to this warranty and, depending on Licensee's jurisdiction, may have additional rights.</li>
                        <li>In case of a breach of the Limited Warranty, Licensee's exclusive remedy is as follows: Licensee will return all copies of the Software to Licensor, at Licensee's cost, along with proof of purchase. (Licensee can obtain a step-by-step explanation of this procedure, including a return authorization code, by contacting Licensor at [address and toll free telephone number].) At Licensor's option, Licensor will either send Licensee a replacement copy of the Software, at Licensor's expense, or issue a full refund.</li>
                        <li>Notwithstanding the foregoing, LICENSOR IS NOT LIABLE TO LICENSEE FOR ANY DAMAGES, INCLUDING COMPENSATORY, SPECIAL, INCIDENTAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, CONNECTED WITH OR RESULTING FROM THIS LICENSE AGREEMENT OR LICENSEE'S USE OF THIS SOFTWARE. Licensee's jurisdiction may not allow such a limitation of damages, so this limitation may not apply.</li>
                        <li>Licensee agrees to defend and indemnify Licensor and hold Licensor harmless from all claims, losses, damages, complaints, or expenses connected with or resulting from Licensee's business operations.</li>
                        <li>Licensor has the right to terminate this License Agreement and Licensee's right to use this Software upon any material breach by Licensee.</li>
                        <li>Licensee agrees to return to Licensor or to destroy all copies of the Software upon termination of the License.</li>
                        <li>This License Agreement is the entire and exclusive agreement between Licensor and Licensee regarding this Software. This License Agreement replaces and supersedes all prior negotiations, dealings, and agreements between Licensor and Licensee regarding this Software.</li>
                        <li>This License Agreement is governed by the law of New York applicable to New York contracts.</li>
                        <li>This License Agreement is valid without Licensor's signature. It becomes effective upon the earlier of Licensee's signature or Licensee's use of the Software.</li>
                    </ol>
                </>
            );
        }
    },
    {
        topic: "enter-register-name-key",
        content: () => {
            return (
                <>
                    <h1>Enter register name/key</h1>
                    <p>After you have placed an order of Backup8, you will receive your registration information within 1 hour, please enter the register name and key to the register-form to register it. Make sure you have deleted all empty spaces around the name and key before you click Register button.</p>
                </>
            );
        }
    },
    {
        topic: "crash-report",
        content: () => {
            return (
                <>
                    <h1>Crash report</h1>
                    <p>If Backup8 crashes, a crash collector program will popup, it will ask you to save the crash dump file to computer, please save the file and send it to us, we will help you to find why the program crashes on your computer. Send the file to <a href="mailto:support@staticbackup.com">support@staticbackup.com</a></p>
                </>
            );
        }
    },
    {
        topic: "log-report",
        content: () => {
            return (
                <>
                    <h1>Log report</h1>
                    <p>If you have any question about the backup or restore, please send the log file to us, and we will help you to find what happend on your computer. Send the file to <a href="mailto:support@staticbackup.com">support@staticbackup.com</a></p>
                </>
            );
        }
    }
]

function Backup8Help() {
    return <Help uri="/backup8/help" topics={topics} sidebar={Sidebar} />;
}

export default Backup8Help;
