import React from "react";
import "./Common.scss";

import Button from "./Button";
import { More } from "./ButtonPanel";

import PayPP from "./pay-pp";
import PayMS from "./pay-ms";
import PayVisa from "./pay-visa";
import PayAmex from "./pay-amex";

import InfoActivation from "./info-activation";
import InfoMoneyback from "./info-moneyback";
import InfoSecure from "./info-secure";
import InfoSupport from "./info-support";

import GirlImage from "./SupportGirl.png";

export function PurchaseCards() {
    return (
        <div className="Purchase-cards">
            <PayVisa />
            <PayMS />
            <PayAmex />
            <PayPP />
        </div>
    );
}

export function PurchasePanel(props) {
    const { title, type, num, cost, original, saved, img, url } = props;
    let cc;
    if (type === "copy") {
        cc = num > 1 ? "copies" : "copy";
    } else {
        cc = num > 1 ? "years" : "year";
    }

    return (
        <div className="Purchase-panel">
            <img className="Purchase-icon" src={img} alt={title} />
            <div className="SmallTitle-gray">
                License for {num} {cc}
            </div>
            <div>
                {cost} (<span className="Purchase-original-price">{original}</span>)
            </div>
            <div className="Purchase-saved">Saved {saved}</div>
            <Button type="Blue" caption="Purchase" url={url} />
        </div>
    );
}

export function PurchaseBenefit() {
    return (
        <>
            <div className="Purchase-benefits">
                <div className="Purchase-benefit-item">
                    <InfoActivation width={16} />
                    &nbsp; Instant Activation automatically
                </div>
                <div className="Purchase-benefit-item">
                    <InfoMoneyback width={16} />
                    &nbsp; 30-day money back guarantee
                </div>
                <div className="Purchase-benefit-item">
                    <InfoSupport width={16} />
                    &nbsp; 24/7 Technical and Sales Support
                </div>
                <div className="Purchase-benefit-item">
                    <InfoSecure width={16} />
                    &nbsp; Secure payment encryption
                </div>
            </div>
        </>
    );
}

export function SupportArea(props) {
    const { urlHelp, urlFAQ, hi } = props;
    const clsName = hi ? "Section SupportBanner-hibg" : "Section SupportBanner-bg";
    return (
        <div className={clsName}>
            <div className="Content SupportBanner">
                <img className="SupportBanner-girl" src={GirlImage} alt="Girl" />
                <div className="SupportBanner-desc">
                    <div className="SmallTitle-gray SupportBanner-title">Support</div>
                    <p>
                        Browse the HELP and FAQ pages will help you to understand how to use StaticSync or find the
                        solution for your issues.
                    </p>
                    <p>
                        If you still cannot find what you want, please let us know by sending an email to&nbsp;
                        <a href="mailto:support@staticbackup.com">support@staticbackup.com</a>.
                    </p>
                    <br />
                    <div className="SupportBanner-buttons">
                        <Button caption="HELP" url={urlHelp} />
                        &nbsp;&nbsp;&nbsp;
                        <Button caption="FAQ" url={urlFAQ} />
                    </div>
                </div>
            </div>
        </div>
    );
}

function renderIcon(icon, name) {
    if (icon) {
        return <img className="Products-item-icon" src={icon} alt={name} /> 
    } else {
        return <div>&nbsp;</div>
    }
}

function renderMoreUrl(urlMore) {
    if (urlMore) {
        return <More pad url={urlMore} />;
    } else {
        return <></>;
    }
}

export function ProductNameLine(props) {
    const { icon, name, version, urlMore } = props;
    return (
        <div className="Products-item-line">
            {renderIcon(icon, name)}
            <div className="Products-item-name">
                {name}
                <span className="Products-item-version"> {version}</span>
            </div>
            <div className="Products-item-more">{renderMoreUrl(urlMore)}</div>
        </div>
    );
}
