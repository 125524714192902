import React from "react";

import { SupportArea } from "./Common";

function Backup8Document() {
    return (
        <SupportArea urlHelp="/backup9/help" urlFAQ="/backup9/faq" />
    );
}

export default Backup8Document;
