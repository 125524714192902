import React from "react";
import "./Header.scss";

import Menu from "./Menu";
import MenuItems from "./MenuItems";

import Logo from "./Logo.png";

function Header() {
    return (
        <div className="Section Header-bg">
            <div className="Content Header">
                <a className="Header-logo-container" href="/">
                    <img className="Header-logo" src={Logo} alt="Logo" />
                    <span className="Header-logo-text">StaticBackup</span>
                </a>
                <Menu items={MenuItems} />
            </div>
        </div>
    );
}

export default Header;
