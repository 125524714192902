import Screenshot01 from "./Backup9Screenshot01.jpg";
import Screenshot02 from "./Backup9Screenshot02.jpg";
import Screenshot03 from "./Backup9Screenshot03.jpg";
import Screenshot04 from "./Backup9Screenshot04.jpg";
import Screenshot05 from "./Backup9Screenshot05.jpg";
import Screenshot06 from "./Backup9Screenshot06.jpg";
import Screenshot07 from "./Backup9Screenshot07.jpg";
import Screenshot08 from "./Backup9Screenshot08.jpg";

export const Backup9Screenshots = [
    {
        title: "Check all backable items",
        desc:
            "In the main interface, you can easily check all the backupable items. The backup program will automatically find all the backupable data and display the size of these data.",
        src: Screenshot01,
        srcWin: Screenshot01
    },
    {
        title: "Adjust backup parameters",
        desc:
            "After clicking the backup button, the backup dialog will be displayed. In this window, the backup parameters will be displayed and you can adjust them as needed.",
        src: Screenshot02,
        srcWin: Screenshot02
    },
    {
        title: "Setup a backup plan",
        desc:
            "Open the Backup Scheduler and select the backup type to back up by hour, day, week or month. At the same time you need to set the specific time when the backup starts.",
        src: Screenshot03,
        srcWin: Screenshot03
    },
    {
        title: "Setup a backup plan",
        desc:
            "You need to select which items need to be scheduled into the backup plan, or click \"Select all\" to select all items.",
        src: Screenshot04,
        srcWin: Screenshot04
    },
    {
        title: "Setup a backup plan",
        desc:
            "Similarly, you can also set parameters for your plan. At the same time, you also need to set the destination folder and file name generation method for automatic backup.",
        src: Screenshot05,
        srcWin: Screenshot05
    },
    {
        title: "Preferences",
        desc:
            "In the preferences, you can set a default folder for each backup and determine how the file name is generated.",
        src: Screenshot06,
        srcWin: Screenshot06
    },
    {
        title: "Clouds",
        desc:
            "You can add one or more cloud accounts, and choose to automatically upload to the specified cloud account in the backup dialog or scheduler settings.",
        src: Screenshot07,
        srcWin: Screenshot07
    },
    {
        title: "Clouds",
        desc:
            "When accessing cloud storage, you can set up to use a proxy server.",
        src: Screenshot08,
        srcWin: Screenshot08
    }
];
