import React from 'react';
import "./Privacy.scss";

function Privacy() {
    return (
        <div className="Section Privacy-bg">
            <div className="Content Privacy">
                <h1>Overview</h1>
                <p> We strongly support your right to privacy when using our apps. Our privacy policy is simple: your data is none of our business. We make money by selling software, not by mining your personal information. To the extent that our apps and websites can provide their functionality without doing so, we prefer to avoid collecting data from you. In the cases where we do collect data, we try to be clear about why we're collecting it, tell you how long we keep it, delete it when we no longer need it, and give you the ability to opt out of collection whenever possible. We use modern security measures to protect collected data, and limit access to only those employees who require access to perform their jobs. We may be legally required to disclose collected data to law enforcement or government agencies in some situations. </p>
                <h1>Website Purchases</h1>
                <div>
                    <p>If you purchase a product directly from our website, we collect your:</p>
                    <ul>
                        <li>full name</li>
                        <li>address</li>
                        <li>company name (optionally)</li>
                        <li>email address</li>
                    </ul>
                    <p>The purpose of collecting this information is to identify you in the future as a license owner of one of our applications. We may need to verify you are a license owner if, for example:</p>
                    <ul>
                        <li>you contact us for support</li>
                        <li>
                            you request a receipt or invoice
                        </li>
                        <li>
                            you request a refund
                        </li>
                        <li>
                            you request that we re-send your serial number
                        </li>
                        <li>
                            you wish to receive discounted upgrade pricing on an app you've previously purchased
                        </li>
                    </ul>
                    <p>Because addresses, both real-world and electronic, tend to change over time, having more than one piece of identifying information helps ensure we can find a record of your purchase should you need help down the road.</p>
                    <p>We do not sell or otherwise disclose this information to third-parties, except as required to complete your transaction. For example, we send it to our credit card processor once at the time of purchase in order to authorize the transaction.</p>
                    <p>We do not collect or store your credit card number, expiration date, or CVV code. When you purchase from our website, your credit card details are routed directly from your browser to our credit card processor, and are not stored on any StaticBackup-owned server even temporarily.</p>
                    <p>You may update your personal information with us at any time by emailing support@staticbackup.com.</p>
                    <p>We retain the personal information related to your purchase indefinitely to facilitate support interactions, unless you ask us to remove it. To request removal of your personal information related to a purchase, contact support@staticbackup.com. If you do this, be sure to keep a copy of your purchased serial numbers, as we will no longer be able to look them up once we have removed your identifying information from our system.</p>
                    <h1>Support Interactions</h1>
                    <p>When handling support requests from you, we collect:</p>
                    <ul>
                        <li>your email address (or Twitter handle, if you contact us via Twitter)</li>
                        <li>any information you provide voluntarily (such as crash logs or other diagnostic info)</li>
                    </ul>
                    <p>This information is collected solely to help resolve your support inquiry. We retain support emails indefinitely in order to:</p>
                    <ul>
                        <li>have context from previous interactions which may help us answer your future questions more quickly</li>
                        <li>identify broad trends in support requests, which may help us identify and solve problems with our products</li>
                    </ul>
                    <p>If you would like to remove support emails you have sent, contact support@staticbackup.com.</p>
                    <h1>Application Usage Analytics</h1>
                    <p>Some of our apps may, by default, collect information about the way you use them. This data is anonymized before it is sent to us, and cannot be used to personally identify you.</p>
                    <p>We look only for broad patterns in the aggregated usage data, such as whether or not a particular feature is frequently used, or whether users in general prefer one setting over another. This helps us make informed decisions about the future development of our apps.</p>
                    <p>To be clear, we do not track individual user behavior in our apps. We do not receive information from your device's displays, cameras, or microphones.</p>
                    <p>We retain usage analytics data for 30 days.</p>
                    <h1>Crash Logs</h1>
                    <p>By default, if one of our apps crashes while you're using it, anonymized data about the crash will be collected to help us identify the cause of the crash and hopefully fix it in a future update. These "crash logs" contain information such as the state of the app, operating system, and device at the time of the crash, but not your private data.</p>
                    <p>In our direct download apps, you may have the option to provide your name, email address, and additional comments when submitting a crash report, but this information is not required. If you do not voluntarily provide your name or email address, nothing else in the crash log can be used to personally identify you. Whenever possible, the app will allow you to review the entire contents of the crash log before you decide whether or not to send it.</p>
                    <p>Your crash log may be sent to a third-party vendor for collection and aggregation. Please refer to our list of third-party vendors.</p>
                    <p>We retain crash logs for 30 days.</p>
                    <p>Apple may also collect crash logs if the privacy settings of your device allow it.</p>
                    <h1>Update Checking</h1>
                    <p>By default, our Mac apps periodically check to see if a newer version of the app is available, so that you can be given the choice to update if you wish.</p>
                    <p>For apps acquired from the Mac App Store, update checking behavior is managed by the operating system, and the relevant information is processed by Apple.</p>
                    <p>For apps acquired via direct download from StaticBackup's website, an update check request will be occasionally sent to a StaticBackup-owned server. This request contains the name and current version of the app you are using, and a small amount of metadata about your device (such as which operating system version it is running, and your preferred language) which may be necessary to guide you to the correct update version.</p>
                    <p>You may turn off update checking from the app's preferences window.</p>
                    <h1>Email List</h1>
                    <p>You may be given a one-time opportunity to sign up for our email newsletter the first time you open one of our apps. If you decline, no data will be sent. If you accept, the email address you provide will be added to our email list.</p>
                    <p>Our email list is low volume (only a few messages per year is typical) and is generally limited to announcements of important new versions of our apps or significant new product releases.</p>
                    <p>We do not sell or otherwise disclose any portion of our email list to third-parties, with the exception of the vendor that provides our mailing list services as necessary to distribute the emails.</p>
                    <h1>Activation</h1>
                    <p>Activation is the process by which our applications verify that you are a legitimately licensed owner of the StaticBackup product you're using.</p>
                    <p>For apps acquired from the Mac App Store, no activation request is sent to StaticBackup-owned servers. Verification of your purchase may occur by processes and servers managed by Apple in this case.</p>
                    <p>For apps acquired via direct download from StaticBackup's website, an activation request is performed when you enter a serial number to unlock the app, and may be repeated from time to time by already activated products.</p>
                    <p>The activation process consists of a single request sent to a StaticBackup-owned server, containing encrypted information about the serial number you entered into the app. The server verifies whether the serial number is valid, and replies with a digitally signed confirmation if so. Otherwise, an error message is sent back for the app to display to you.</p>
                    <p>We retain a log of activation requests for one week.</p>
                    <h1>Logging</h1>
                    <p>When you interact with our servers using a web browser, or indirectly by network requests sent on your behalf by our apps, some metadata about the request is logged. This metadata may include:</p>
                    <ul>
                        <li>
                            your IP address (may reveal your approximate geographic location)
                        </li>
                        <li>
                            the name of the resource requested
                        </li>
                        <li>
                            the name and version number of the software making the request (may reveal information about your web browser, operating system, and their configuration)
                        </li>
                        <li>
                            whether or not the request was successful
                        </li>
                        <li>
                            current date and time
                        </li>
                    </ul>
                    <p>We generally don't look at these logs unless a server is malfunctioning or appears to be getting used in a malicious way. We may look at the information in aggregate to see broad statistics such as how many times our apps have been downloaded, or from which source an unusually high amount of network traffic is arriving.</p>
                    <p>We retain web server logs for one month.</p>
                    <h1>Third-Party Vendor Services Used</h1>
                    <ul>
                        <li>Credit card processing for purchases from our website is provided by <a href="https://www.mycommerce.com/">MyCommerce</a></li>
                        <li><a href="https://www.paypal.com">PayPal</a> is used for purchases from our website where PayPal is selected as the payment method.</li>
                        <li>Web analytics services are provided by <a href="https://analytics.google.com">Google Analytics</a>.</li>
                    </ul>
                    <h1>Data Not Collected</h1>
                    <p>Except as described above, and as required to perform the application's core functionality at the user's request, StaticBackup apps do not send out any private information. This includes:</p>
                    <ul>
                        <li>Information from device sensors</li>
                        <li>Your keyboard input</li>
                        <li>Screen contents</li>
                        <li>Network traffic</li>
                        <li>Hostnames</li>
                        <li>Usernames</li>
                        <li>Passwords</li>
                        <li>SSH / Encryption keys</li>
                        <li>Contents of files you are working with</li>
                    </ul>
                    <p>Apps like Transmit, whose core purpose is to send and receive your documents over a network, will, of course, send and receive your documents at your request, but not to StaticBackup or any other third party. Documents in transit will be encrypted only if you use a protocol which supports encryption, such as SFTP, HTTPS, etc., in conjunction with a correctly configured server. It is your responsibility to be aware of the security implications of the file transfer protocols you choose to use. Plain FTP is not encrypted.</p>
                    <h1>Backed-up Data</h1>
                    <p>As you might expect, we keep backups of company data so that a catastrophic data loss event doesn't put us out of business. Although collected personal data expires from our "active" data set according to the schedules mentioned above, it may persist in backups for up to 6 months. Backups are only accessible to specially privileged employees who perform system administration tasks. We consider the backups "cold storage" and we don't pull data from them unless a significant data loss event has occurred.</p>
                    <h1>Rights of EU Citizens Under GDPR</h1>
                    <p>Citizens of the EU may exercise their rights under the General Data Protection Regulation, such as the rights of access and erasure, by contacting us with their request. We recommend emailing the request to gdpr@staticbackup.com.</p>
                    <h1>Questions and Feedback</h1>
                    <p>Our privacy policies might change or be edited for clarity over time. Up-to-date information will always be available from this page. Please <a href="mailto:privacy@staticbackup.com">contact us</a> if you have any questions about our data collection or privacy policies. We'll be more than happy to discuss them with you.</p>
                </div>
            </div>
        </div >
    );
}

export default Privacy;
