import React from "react";
import "./StaticSyncBanner.scss";

import { STATICSYNC_VER, isMacOS } from "./Consts";

import ButtonPanel from "./ButtonPanel";

function StaticSyncBanner(props) {
    const { urlMore } = props;
    const clsName = isMacOS() ? "Content StaticSyncBanner_macOS" : "Content StaticSyncBanner";
    return (
        <div className="Section StaticSyncBanner-bg">
            <div className={clsName}>
                <div className="StaticSyncBanner-content">
                    <div className="Banner-intro-text FlexAlignCenter StaticSyncBanner-intro">
                        <h2>StaticSync {STATICSYNC_VER}</h2>
                        <h1 className="AdWords">
                            Synchronize files to clouds
                            <br />
                            precise control, efficiency
                        </h1>
                    </div>
                    <div className="Banner-button-panel-center">
                        <ButtonPanel dir="column" caption="Try it free" url="/download/staticsync" urlMore={urlMore} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StaticSyncBanner;
