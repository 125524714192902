import React, { useEffect } from "react";
import "./App.scss";

import ReactGA from "react-ga";

import { Route, Switch, useLocation } from "react-router-dom";
import Screenshots from "./Screenshots";

import Header from "./Header";
import Footer from "./Footer";
import DownloadAction from "./DownloadAction";
import WhatsNew from "./WhatsNew";

import Products from "./Products";
import OldProducts from "./OldProducts";
import Downloads from "./Downloads";
import { PURCHASE_BACKUP_PRODUCTS } from "./Consts";
import { PurchaseStaticSyncPage, PurchaseStaticBackupPage } from "./PurchasePage";
import Support from "./Support";
import ProductsDocument from "./ProductsDocument";
import Company from "./Company";
import AboutHighlights from "./AboutHighlights";

import Backup8Banner from "./Backup8Banner";
import Backup8Welcome from "./Backup8Welcome";
import Backup8Desc from "./Backup8Desc";
import Backup8SupportsSoftware from "./Backup8SupportsSoftware";
import Backup8Document from "./Backup8Document";
import Backup8Feedback from "./Backup8Feedback";
import Backup8Help from "./Backup8Help";
import Backup8FAQ from "./Backup8FAQ";

import { Backup9Screenshots } from "./Backup9Screenshots";

import Backup9Banner from "./Backup9Banner";
import Backup9Welcome from "./Backup9Welcome";
import Backup9Desc from "./Backup9Desc";
import Backup9SupportsSoftware from "./Backup9SupportsSoftware";
import Backup9Document from "./Backup9Document";
import Backup9Help from "./Backup9Help";
import Backup9FAQ from "./Backup9FAQ";

import FreeUpgradeBackup8 from "./FreeUpgradeBackup8";

import StaticSyncBanner from "./StaticSyncBanner";
import StaticSyncBrief from "./StaticSyncBrief";
import StaticSyncFeatures from "./StaticSyncFeatures";
import StaticSyncSupportClouds from "./StaticSyncSupportClouds";
import { StaticSyncScreenshots } from "./StaticSyncScreenshots";
import StaticSyncDownloads from "./StaticSyncDownloads";
import StaticSyncDocument from "./StaticSyncDocument";
import StaticSyncHelp from "./StaticSyncHelp";
import StaticSyncFAQ from "./StaticSyncFAQ";

import Terms from "./Terms";
import Privacy from "./Privacy";

import Blog from "./Blog";
import BlogIndex from "./BlogIndex";
import { Jan_17_2020 } from "./Blog_17_Jan_2020";

const Blogs = [Jan_17_2020];

function App() {
    let location = useLocation();
    useEffect(() => {
        ReactGA.pageview(location.pathname);
    });
    const { backup8, backup9 } = PURCHASE_BACKUP_PRODUCTS;
    return (
        <div className="App">
            <Header />
            <Switch>
                <Route exact path="/">
                    <StaticSyncBanner urlMore="/staticsync" />
                    <Backup9Banner />
                    <Backup8Banner />
                </Route>
                <Route exact path="/products">
                    <Products />
                    <OldProducts />
                </Route>
                <Route exact path="/downloads">
                    <Downloads />
                </Route>
                <Route exact path="/purchase">
                    <PurchaseStaticSyncPage />
                    <PurchaseStaticBackupPage product={backup9} />
                    <PurchaseStaticBackupPage product={backup8} />
                </Route>
                <Route exact path="/support">
                    <Support />
                    <ProductsDocument />
                </Route>
                <Route exact path="/blog">
                    <BlogIndex blogs={Blogs} />
                </Route>
                <Route exact path="/blog/:index">
                    <Blog blogs={Blogs} />
                </Route>
                <Route exact path="/about">
                    <Company />
                    <AboutHighlights />
                </Route>
                <Route exact path="/whatsnew">
                    <WhatsNew />
                </Route>
                <Route exact path="/download/:name">
                    <DownloadAction />
                </Route>
                <Route exact path="/terms">
                    <Terms />
                </Route>
                <Route exact path="/privacy">
                    <Privacy />
                </Route>
                {/* StaticSync related pages */}
                <Route exact path="/staticsync">
                    <StaticSyncBrief />
                    <StaticSyncDownloads />
                    <StaticSyncFeatures />
                    <Screenshots screenshots={StaticSyncScreenshots} />
                    <StaticSyncSupportClouds />
                    <PurchaseStaticSyncPage />
                    <StaticSyncDocument hi />
                </Route>
                <Route exact path="/staticsync/help/:topic?">
                    <StaticSyncHelp />
                </Route>
                <Route exact path="/staticsync/faq">
                    <StaticSyncFAQ />
                </Route>
                {/* Backup8 related pages */}
                <Route exact path="/backup8">
                    <Backup8Welcome />
                    <Backup8Desc />
                    <Backup8SupportsSoftware />
                    <Backup8Feedback />
                    <PurchaseStaticBackupPage product={backup8} />
                    <Backup8Document />
                </Route>
                <Route exact path="/backup8/help/:topic?">
                    <Backup8Help />
                </Route>
                <Route exact path="/backup8/faq">
                    <Backup8FAQ />
                </Route>
                <Route exact path="/freeupgradebackup8">
                    <FreeUpgradeBackup8 />
                </Route>
                <Route exact path="/backup9">
                    <Backup9Welcome />
                    <Backup9Desc />
                    <Backup9SupportsSoftware />
                    <Screenshots screenshots={Backup9Screenshots} />
                    <PurchaseStaticBackupPage product={backup9} />
                    <Backup9Document />
                </Route>
                <Route exact path="/backup9/help/:topic?">
                    <Backup9Help />
                </Route>
                <Route exact path="/backup9/faq">
                    <Backup9FAQ />
                </Route>
                <Route path="*">
                    <Products />
                    <OldProducts />
                </Route>
            </Switch>
            <Footer />
        </div>
    );
}

export default App;
