import React, { useState } from "react";
import "./Screenshots.scss";

import { isMacOS } from "./Consts";

function ScreenshotImage(props) {
    const { title, desc, src, srcWin } = props;
    const imgSrc = isMacOS() ? src : srcWin;
    return (
        <div className="Screenshots-img">
            <div className="SmallTitle-gray">{title}</div>
            <div className="Screenshot-desc">{desc}</div>
            <img src={imgSrc} alt="Screenshots" />
        </div>
    );
}

function ScreenshotManager(props) {
    const { screenshots } = props;
    const [index, setIndex] = useState(0);
    const img = screenshots[index];
    const timeoutRef = setTimeout(() => {
        if (index + 1 >= screenshots.length) {
            setIndex(0);
        } else {
            setIndex(index + 1);
        }
    }, 5000);
    return (
        <>
            <ScreenshotImage {...img} />
            <div className="Screenshots-switch-btns">
                {screenshots.map((screen, key) => {
                    const clsName = key === index ? "Screenshots-switch-btn-hl" : "Screenshots-switch-btn";
                    return (
                        <div
                            key={key}
                            className={clsName}
                            onClick={e => {
                                e.preventDefault();
                                setIndex(key);
                                clearTimeout(timeoutRef);
                            }}
                        />
                    );
                })}
            </div>
        </>
    );
}

function Screenshots(props) {
    const { screenshots } = props;
    return (
        <div className="Section Screenshots-bg">
            <div className="Content Screenshots">
                <ScreenshotManager screenshots={screenshots} />
            </div>
        </div>
    );
}

export default Screenshots;
