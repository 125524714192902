import React from "react";
import "./Backup8Banner.scss";

import ButtonPanel from "./ButtonPanel";

function Backup8Banner() {
    return (
        <div className="Section Backup8Banner-bg">
            <div className="Content Backup8Banner">
                <div className="Banner-intro-text">
                    <div className="Banner-title">
                        <h2>Backup 8</h2>
                    </div>
                    <h1 className="AdWords">Backup your data<br />from famous browsers,<br />email clients from your<br />Windows system</h1>
                    <div className="Banner-button-panel">
                        <ButtonPanel caption="Try it free" url="/download/backup8" urlMore="/backup8" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Backup8Banner;
