import React from "react";
import "./Backup9SupportsSoftware.scss"

import { SUPPORT_FILES } from "./Backup9Common";

function Backup9SupportsSoftware() {
    return (
        <div className="Section Backup9SupportsSoftware-bg">
            <div className="Content Backup9SupportsSoftware">
                <h1>Backup9 will backup personal data from macOS</h1>
                <div className="Backup9SupportList">
                    {
                        SUPPORT_FILES.map((entry, key) => {
                            return (
                                <div key={key} className="Backup9SupportListEntry">
                                    <img src={entry.img} alt="Supported software" />
                                    <span className="BoldText SmallText">{entry.name}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Backup9SupportsSoftware;
