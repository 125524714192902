const MenuItems = [
    {
        caption: "WHAT'S NEW",
        href: "/whatsnew"
    },
    {
        caption: "PRODUCTS",
        href: "/products"
    },
    {
        caption: "DOWNLOADS",
        href: "/downloads"
    },
    {
        caption: "PURCHASE",
        href: "/purchase"
    },
    {
        caption: "SUPPORT",
        href: "/support"
    },
    {
        caption: "BLOG",
        href: "/blog"
    },
    {
        caption: "ABOUT",
        href: "/about"
    }
];

export default MenuItems;
