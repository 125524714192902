import React from "react";
import "./Button.scss";

function Button(props) {
    const { caption, type, big, url, onClick } = props;
    let clsName = "Button";
    if (type === "Blue") {
        clsName = "Button-blue";
    } else if (type === "Gray") {
        clsName = "Button-gray";
    }
    if (big) {
        clsName += "-big";
    }
    return (
        <a className={clsName} href={url} onClick={onClick}>
            {caption}
        </a>
    );
}

export default Button;
