import React from "react";
import FeatureIcon from "./FeatureIcon";

import "./StaticSyncFeatures.scss";

const features = [
    {
        title: "Corresponds directly to a local folder",
        desc:
            "The synchronization location of traditional cloud clients is fixed. You must save a partial or complete copy of the file locally. To synchronize files to the cloud, you must copy your files to the folder bound to the cloud. This caused a waste of storage space. And StaticSync can directly correspond to the scattered folders on the hard disk and the cloud folders by creating different synchronization projects. This not only saves hard disk space, but also makes synchronization operations more flexible."
    },
    {
        title: "All in one",
        desc:
            "In the traditional mode, you need to download different cloud clients for different clouds. If you use more clouds, you must install multiple cloud clients at the same time. But you don't need any cloud client to use StaticSync, because we have integrated the functions of each cloud into StaticSync, you only need StaticSync to access each cloud client."
    },
    {
        title: "Multiple accounts",
        desc:
            "StaticSync can add multiple different accounts of the same type of cloud. For example, you can bind multiple Dropbox accounts to StaticSync at the same time, so you can synchronize different files or folders to different cloud storage spaces at the same time."
    },
    {
        title: "Smart synchronization",
        desc:
            'StaticSync intelligently synchronizes and updates files, including those that have been deleted. But if you modify a file on both sides at the same time, this file will be marked as "conflict", StaticSync will show the conflicting file and let you choose the specific subsequent operation.'
    },
    {
        title: "File filters",
        desc:
            "StaticSync can use filters to limit the synchronization content when synchronizing files, such as only synchronizing image files or removing some unnecessary files."
    },
    {
        title: "Support proxy server",
        desc:
            "StaticSync supports http / socks4 / socks5 proxy, and you can choose to use or not use a proxy server for different cloud accounts, or even choose different proxy servers separately."
    },
    {
        title: "Easy to use",
        desc:
            "Concise UI, try to provide only necessary functions to the user, the user can get started at the beginning, without long-term learning."
    }
];

function renderFeatureItem(feature, key) {
    const { title, desc } = feature;
    return (
        <div key={key} className="StaticSyncFeatures-list-item">
            <div className="StaticSyncFeatures-feature-icon">
                <FeatureIcon />
            </div>
            <div>
                <div>
                    <strong>{title}</strong>
                </div>
                <div>{desc}</div>
            </div>
        </div>
    );
}

function StaticSyncFeatures() {
    return (
        <div className="Section StaticSyncFeatures-bg">
            <div className="Content StaticSyncFeatures">
                <h1>Features</h1>
                <div className="StaticSyncFeatures-list">
                    {features.map((feature, key) => {
                        return renderFeatureItem(feature, key);
                    })}
                </div>
            </div>
        </div>
    );
}

export default StaticSyncFeatures;
