import React from "react";
import "./FAQ.scss";

function FAQ() {
    return (
        <div className="Section FAQ-bg">
            <div className="Content FAQ">
                <h1>Frequently asked questions (FAQs)</h1>
                <div>
                    <ol>
                        <li>
                            <h2>Why use StaticSync, how is it different from other cloud clients?</h2>
                            <p>StaticSync not only can synchronize files to your cloud storage, it also does not require you to manually copy files to the cloud's folder. You only need to associate a folder with the cloud storage folder, StaticSync will synchronize them to cloud storage. In addition, it can add one or more filters to specify synchronized files. Using StaticSync is more flexibly and quickly.</p>
                            <p><strong>Features</strong></p>
                            <ul>
                                <li>
                                    <strong>It is very efficient</strong> - StaticSync is written in C++ language. Parallel-task management is built-in.
                                </li>
                                <li>
                                    <strong>It is cross-platform software</strong> - Now it supports two platforms: Windows/MacOS.
                                </li>
                                <li>
                                    <strong>It is very easy to use</strong> - A few clicks you can setup a project for your files and StaticSync will keep tracing all your files and ensure them up-to-date.
                                </li>
                                <li>
                                    <strong>It is security</strong> - You are able to use proxy server to transfer your data to clouds.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h2>What is "Conflict"?</h2>
                            <p>When your file is marked as "Conflict", this indicates that the file has been changed on both your local and cloud storage, or perhaps one side has been changed and the other side has been deleted. At this point you need to decide on the final operation of these files marked as "Conflict". You can choose one of the following actions: Upload, download, delete from local, delete from remote, or you can't decide now, you can choose Ignore.</p>
                        </li>
                        <li>
                            <h2>
                                StaticSync (Windows platform) keeps telling me that the program doesn't has the
                                privileges to register as a handler to receive the authorization, what should I do?
                            </h2>
                            <p>
                                You need to grant the access privileges to StaticSync application to allow it receives
                                the authorization information, if StaticSync doesn't has the privileges it will not able
                                to access your files/folders from the cloud storage. To solve the problem, you need to
                                run StaticSync with administrator privileges. Once the privileges are granted, you can
                                run StaticSync in normal mode.
                            </p>
                            <ul>
                                <li>Exit StaticSync</li>
                                <li>Click `Windows` button and show the start menu</li>
                                <li>Enter `StaticSync` to find the main-icon of StaticSync</li>
                                <li>Right click on `StaticSync` and select `More` -> `Run as administrator`</li>
                            </ul>
                        </li>
                        <li>
                            <h2>Currently only seeing support for OneDrive, is there any other cloud platform support plan?</h2>
                            <p>Yes, we are adding support for access to Dropbox and Google Drive, which is expected to be accessed and tested in one month.</p>
                        </li>
                        <li>
                            <h2>
                                Does StaticSync support proxy server?
                            </h2>
                            <p>Yes, StaticSync supports multiple proxy servers at the same time. You can add multiple proxy servers and assign them to different cloud platforms, StaticSync will access the cloud platform through different proxy servers.</p>
                        </li>

                    </ol>
                </div>
            </div>
        </div>
    );
}

export default FAQ;
