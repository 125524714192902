import React from "react";
import "./ProductsDocument.scss";

import { ProductNameLine } from "./Common";

import { STATICSYNC_VER, BACKUP8_VER, BACKUP9_VER } from "./Consts";

import Backup8Icon from "./backup8imgs/backup8.png";
import Backup9Icon from "./backup9imgs/Backup9.png";
import StaticSyncIcon from "./StaticSyncIcon.png";

const documents = [
    {
        icon: StaticSyncIcon,
        version: STATICSYNC_VER,
        name: "StaticSync",
        urlMore: "/staticsync",
        help: "/staticsync/help",
        faq: "/staticsync/faq"
    },
    {
        icon: Backup9Icon,
        version: BACKUP9_VER,
        name: "Backup9",
        urlMore: "/backup9",
        help: "/backup9/help",
        faq: "/backup9/faq"
    },
    {
        icon: Backup8Icon,
        version: BACKUP8_VER,
        name: "Backup8",
        urlMore: "/backup8",
        help: "/backup8/help",
        faq: "/backup8/faq"
    }
];

function renderDoc(doc, key) {
    const { icon, name, version, urlMore, help, faq } = doc;
    const clsName = key % 2 === 0 ? "ProductsDocument-list-item1" : "ProductsDocument-list-item2";
    return (
        <div className={clsName} key={key}>
            <ProductNameLine icon={icon} name={name} version={version} urlMore={urlMore} />
            <div className="ProductsDocument-list-line">
                <span className="ProductsDocument-link">
                    <a href={help}>Help</a> >
                </span>
                <span className="ProductsDocument-link">
                    <a href={faq}>FAQ</a> >
                </span>
            </div>
        </div>
    );
}

function ProductsDocument() {
    return (
        <div className="Section ProductsDocument-bg">
            <div className="Content ProductsDocument">
                <h1>Documents</h1>
                <p>If you have any questions, it is more efficient to browse our product documentation first.</p>
                <div className="ProductsDocument-list">
                    {documents.map((doc, key) => {
                        return renderDoc(doc, key);
                    })}
                </div>
            </div>
        </div>
    );
}

export default ProductsDocument;
