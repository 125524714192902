import React from "react";
import "./Backup9Welcome.scss";

import Button from "./Button";

function Backup9welcome() {
    return (
        <div className="Section Backup9Welcome-bg">
            <div className="Content Backup9Welcome">
                <h1 className="AdWords CenterText">Backup data from<br />famous browsers, email clients under macOS.</h1>
                <Button caption="Try it free" url="/download/backup9" />
                <p className="SmallText Bold">20-day trial, macOS</p>
            </div>
        </div >
    );
}

export default Backup9welcome;
