import React from "react";

function InfoSecure(props) {
    const { width } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 24 24">
            <path
                fill="#333333"
                d="M13,17 L13,19 L11,19 L11,17 L10,17 L10,14 L14,14 L14,17 L13,17 Z M8,11 L5,11 L4,11 L4,22 L20,22 L20,11 L19,11 L16,11 L8,11 Z M8,9 L8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 L16,9 L17,9 L17,7 C17,4.23857625 14.7614237,2 12,2 C9.23857625,2 7,4.23857625 7,7 L7,9 L8,9 Z M5,9 L5,7 C5,3.13400675 8.13400675,3.89018435e-14 12,3.73034936e-14 C15.8659932,3.74815006e-14 19,3.13400675 19,7 L19,9 L22,9 L22,24 L2,24 L2,9 L5,9 Z M10,9 L14,9 L14,7 C14,5.8954305 13.1045695,5 12,5 C10.8954305,5 10,5.8954305 10,7 L10,9 Z"
                opacity="1"
            />
        </svg>
    );
}

export default InfoSecure;
