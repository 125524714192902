import React from "react";

function InfoActivation(props) {
    const { width } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 24 24">
            <path
                fill="#333333"
                d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 Z M8.10594945,6.18234375 L9.21974022,7.84350904 C8.94853851,8.02534643 8.69550454,8.23342764 8.46446609,8.46446609 C7.53218703,9.39674516 7,10.6549766 7,12 C7,14.7614237 9.23857625,17 12,17 C14.7614237,17 17,14.7614237 17,12 C17,10.6549766 16.467813,9.39674516 15.5355339,8.46446609 C15.3044955,8.23342764 15.0514615,8.02534643 14.7802598,7.84350904 L15.8940506,6.18234375 C16.2732877,6.43661758 16.6269214,6.72742647 16.9497475,7.05025253 C18.2533811,8.35388617 19,10.1190915 19,12 C19,15.8659932 15.8659932,19 12,19 C8.13400675,19 5,15.8659932 5,12 C5,10.1190915 5.7466189,8.35388617 7.05025253,7.05025253 C7.3730786,6.72742647 7.72671228,6.43661758 8.10594945,6.18234375 Z M11,5 L13,5 L13,13 L11,13 L11,5 Z"
                opacity="1"
            />
        </svg>
    );
}

export default InfoActivation;
