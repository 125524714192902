import React from "react";
import "./FAQ.scss";

function Backup8FAQ() {
    return (
        <div className="Section FAQ-bg">
            <div className="Content FAQ">
                <h1>Frequently asked questions (Backup9 for macOS)</h1>
                <ol>
                    <li>
                        <h2>Why Backup9 reports that zero bytes found in some of the backup entries from my system?</h2>
                        <p>You need to allow Backup9 "Full Disk Access" from your macOS, it can be found from "Security & Privacy" dialog.</p>
                        <ul>
                            <li>Click on "Apple" icon from the top left corner of system menu bar, and select "System Preferences".</li>
                            <li>Click on "Security & Privacy" icon.</li>
                            <li>Select "Privacy" page, and on the left list please find "Full Disk Access" item then click it.</li>
                            <li>On the right list you can click "+" to add Backup9GUI and Backup9Scheduler to the list, if the button is gray and cannot be clicked, please unlock it by clicking the "Lock" button on the bottom left corner.</li>
                        </ul>
                    </li>
                    <li>
                        <h2>How to make Scheduler run on my system startup?</h2>
                        <p>You need to add Backup9Scheduler app to the startup list</p>
                        <ul>
                            <li>Open "System Preferences" from the system drop down menu.</li>
                            <li>Click on "Users & Groups"</li>
                            <li>Select the login account and on the right list, please click button "+" to add Backup9Scheduler.</li>
                            <li>On next startup of your system, the scheduler will run automatically.</li>
                        </ul>
                    </li>
                    <li>
                        <h2>Why I cannot register my product?</h2>
                        <p>Please following these steps to register the product:</p>
                        <ul>
                            <li>Please make sure you have downloaded the correct installation package from our website, Click <a href="/backup9">here</a> to visit the download page.</li>
                            <li>Double-click the installation package (DMG file) from your download folder, drag and drop the two Backup9 icons to Application folder to install.</li>
                            <li>Click Backup9 icon from Launchpad to run Backup9.</li>
                            <li>Find your registration information from your order notice email, and please use CTRL+C and CTRL+V to copy the register-email and register-code from your email message.</li>
                            <li>The program will prompt you registration information is correct if you do everything right.</li>
                        </ul>
                    </li>
                    <li>
                        <h2>Is your online order secure?</h2>
                        <p>Yes, all online purchases are guaranteed 100% secure. Your ordering information will never be shared with anyone.</p>
                    </li>
                    <li>
                        <h2>Do I have pay for the upgrades?</h2>
                        <p>No! All future upgrades are always free to registered users! You do not have to pay again. You may install multiple copies of the program as long as only the specified number of copies is in use at one time. I.E. on a single license, you may install at work, at home and on laptop as long as only a single copy of the software is in use at one time.</p>
                    </li>
                    <li>
                        <h2>Do you provide free technical support to your products?</h2>
                        <p>Yes, we provide free technical support to our users and we will respond to all technical support emails in 24 hours, please note registered user's emails always get priority.</p>
                    </li>
                    <li>
                        <h2>I have both a desktop computer and laptop, do I need purchase two license for your product?</h2>
                        <p>No! On a single-user license, you may install at work, at home and on laptop as long as only a single copy of the software is in use at one time.</p>
                    </li>
                    <li>
                        <h2>Do you provide volume discount or educational license?</h2>
                        <p>Yes, please contact <a href="mailto:marketing@staticbackup.com">marketing@staticbackup.com</a> for details.</p>
                    </li>
                    <li>
                        <h2>I don't have a credit card, where else can I buy it?</h2>
                        <p>Please visit <a href="https://www.staticbackup.com/purchase">https://www.staticbackup.com/purchase</a> for instructions for payment via Check/Money order.</p>
                    </li>
                    <li>
                        <h2>I registered the product several months ago, how do I get the latest version?</h2>
                        <p>You can download the latest version of products from <a href="https://www.staticbackup.com/download">https://www.staticbackup.com/download</a>, and use your existing registration code to activate the license on the new version. Please uninstall all previous versions before installing an update.</p>
                    </li>
                    <li>
                        <h2>How to get support?</h2>
                        <p>If your problem isn't in the list, please send your problem to <a href="mailto:support@staticbackup.com">support@staticbackup.com</a></p>
                    </li>
                </ol>
            </div>
        </div>
    );
}

export default Backup8FAQ;
