import Backup8Icon from "./backup8imgs/backup8.png";
import Backup9Icon from "./backup9imgs/Backup9.png";

export const DOWNLOAD_URL_MAC = "https://files.staticbackup.com/StaticSync.dmg";
export const DOWNLOAD_URL_WINDOWS = "https://files.staticbackup.com/StaticSync.zip";
export const DOWNLOAD_URL_MAC_FREE = "https://files.staticbackup.com/StaticSyncFree.dmg";
export const DOWNLOAD_URL_WINDOWS_FREE = "https://files.staticbackup.com/StaticSyncFree.zip";
export const DOWNLOAD_URL_BACKUP8 = "https://files.staticbackup.com/backup8.exe";
export const DOWNLOAD_URL_BACKUP9 = "https://files.staticbackup.com/Backup9.dmg";

export const BUY_URL = "https://order.shareit.com/cart/add?vendorid=200281614&PRODUCT[300968935]=1";
export const BUY_2_URL = "https://order.shareit.com/cart/add?vendorid=200281614&PRODUCT[300968935]=2";
export const BUY_5_URL = "https://order.shareit.com/cart/add?vendorid=200281614&PRODUCT[300975903]=1";

export const BUY_URL_BACKUP8 = "https://order.shareit.com/cart/add?vendorid=200281614&PRODUCT[300944604]=1";
export const BUY_URL_BACKUP8_2 = "https://order.shareit.com/cart/add?vendorid=200281614&PRODUCT[300944604]=2";
export const BUY_URL_BACKUP8_5 = "https://order.shareit.com/cart/add?vendorid=200281614&PRODUCT[300944604]=5";

export const BUY_URL_BACKUP9 = "https://order.shareit.com/cart/add?vendorid=200281614&PRODUCT[300981115]=1";
export const BUY_URL_BACKUP9_2 = "https://order.shareit.com/cart/add?vendorid=200281614&PRODUCT[300981115]=2";
export const BUY_URL_BACKUP9_5 = "https://order.shareit.com/cart/add?vendorid=200281614&PRODUCT[300981115]=5";

export const STATICSYNC_VER = "1.3";
export const BACKUP8_VER = "1.256";
export const BACKUP9_VER = "1.1";

export const STATICSYNC_APPSTORE_URL = "https://apps.apple.com/us/app/staticsync/id1489393897?ls=1&mt=12";
export const STATICSYNC_MICROSOFTSTORE_URL = "ms-windows-store://pdp/?ProductId=9N43733K03J1";

export const PURCHASE_BACKUP_PRODUCTS = {
    backup8: {
        title: "Backup 8",
        desc: "Backup your personal data and settings from famous browsers, email clients under Windows",
        panels: [
            {
                title: "Purchase Backup 8",
                type: "copy",
                num: 1,
                cost: "$29.95",
                original: "$39.95",
                saved: "$10",
                img: Backup8Icon,
                url: BUY_URL_BACKUP8
            },
            {
                title: "Purchase Backup 8",
                type: "copy",
                num: 2,
                cost: "$53.9",
                original: "$79.9",
                saved: "$26",
                img: Backup8Icon,
                url: BUY_URL_BACKUP8_2
            },
            {
                title: "Purchase Backup 8",
                type: "copy",
                num: 5,
                cost: "$127.25",
                original: "$199.75",
                saved: "$72.5",
                img: Backup8Icon,
                url: BUY_URL_BACKUP8_5
            },
        ]
    },
    backup9: {
        title: "Backup 9",
        desc: "Another way to back up your personal data from your macOS, it also supports famous browser, email clients",
        panels: [
            {
                title: "Purchase Backup 9",
                type: "copy",
                num: 1,
                cost: "$29.95",
                original: "$39.95",
                saved: "$10",
                img: Backup9Icon,
                url: BUY_URL_BACKUP9
            },
            {
                title: "Purchase Backup 9",
                type: "copy",
                num: 2,
                cost: "$53.9",
                original: "$79.9",
                saved: "$26",
                img: Backup9Icon,
                url: BUY_URL_BACKUP9_2
            },
            {
                title: "Purchase Backup 9",
                type: "copy",
                num: 5,
                cost: "$127.25",
                original: "$199.75",
                saved: "$72.5",
                img: Backup9Icon,
                url: BUY_URL_BACKUP9_5
            },
        ]
    }
};

export const getDownloadUrl = (name, sysName) => {
    if (name.toLowerCase() === "backup8") {
        return DOWNLOAD_URL_BACKUP8;
    } else if (name.toLowerCase() === "backup9") {
        return DOWNLOAD_URL_BACKUP9;
    }
    if (sysName.toLowerCase() === "macos") {
        return DOWNLOAD_URL_MAC;
    } else {
        return DOWNLOAD_URL_WINDOWS;
    }
};

export const isMacOS = () => {
    return window.navigator.platform.match(/^Mac/);
};

export const operatingSystemName = () => {
    if (isMacOS()) {
        return "MacOS";
    } else {
        return "Windows";
    }
};
