import React from "react";
import { Link } from "react-router-dom";

import Help from "./Help";

function Sidebar() {
    return (
        <div className="Help-sidebar">
            <h1>Introduction</h1>
            <p>
                <Link to="/backup9/help/what-is-backup9">What is Backup9?</Link>
            </p>
            <p>
                <Link to="/backup9/help/how-does-it-work">How does Backup9 work?</Link>
            </p>
            <h1>Start backup</h1>
            <p>
                <Link to="/backup9/help/download-and-install">Download and install</Link>
            </p>
            <p>
                <Link to="/backup9/help/start-backup9">Start Backup9</Link>
            </p>
            <p>
                <Link to="/backup9/help/run-my-first-backup">Run my first backup</Link>
            </p>
            <p>
                <Link to="/backup9/help/decide-the-archive-name">Decide the archive name</Link>
            </p>
            <p>
                <Link to="/backup9/help/schedule-backup">Schedule backup</Link>
            </p>
            <p>
                <Link to="/backup9/help/backup-save-log-file">Save log file</Link>
            </p>
            <h1>Start to restore</h1>
            <p>
                <Link to="/backup9/help/open-an-archive-file">Open an archive file</Link>
            </p>
            <p>
                <Link to="/backup9/help/restore-save-log-file">Save log file</Link>
            </p>
            <h1>Registration</h1>
            <p>
                <Link to="/backup9/help/end-user-license-agreement">End-user license agreement</Link>
            </p>
            <p>
                <Link to="/backup9/help/enter-register-name-key">Enter register name/key</Link>
            </p>
            <h1>Having trouble?</h1>
            <p>
                <Link to="/backup9/help/crash-report">Crash report</Link>
            </p>
            <p>
                <Link to="/backup9/help/log-report">Log report</Link>
            </p>
        </div>
    );
}

const topics = [
    {
        topic: "what-is-backup9",
        content: () => {
            return (
                <>
                    <h1>What is Backup9?</h1>
                    <p>
                        Every day we use computers to generate a lot of data, some of which is very valuable. For
                        example, browser cookies, browsing history, bookmarks, and saved passwords. There are also
                        emails downloaded from the server by your mail client, archived data, etc. When you use office
                        software to edit documents, saved forms, etc. Backup9 provides another way to back up your data.
                        It will automatically pack all your data that needs to be backed up into a file and encrypt and
                        compress it in the way you want. You can even upload archive to it after it completes the backup
                        In the specified cloud storage. Through these methods, Backup9 guarantees that your data is not
                        lost to the greatest extent. Backup9 also provides a scheduler to automatically back up your
                        data. You don't need to worry about backup problems anymore.
                    </p>
                    <h1>Backup9 supports these applications</h1>
                    <ul>
                        <li>Safari</li>
                        <li>Chrome</li>
                        <li>Firefox</li>
                        <li>Internet accounts</li>
                        <li>Mail</li>
                        <li>Mail Preferences</li>
                        <li>Thunderbird</li>
                        <li>Keychain</li>
                        <li>Fonts</li>
                    </ul>
                </>
            );
        }
    },
    {
        topic: "how-does-it-work",
        content: () => {
            return (
                <>
                    <h1>How does Backup9 work?</h1>
                    <p>
                        Backup9 will backup all personal data into one archive file. You can move this file to network,
                        ftp server or burn to cd/dvd disc. You can start backup procedure manually by using backup
                        wizard or you also can use scheduler to arrange the backup procedure and you don't need to take
                        care of it. A scheduler has built-in, you can let the scheduler handles your backup job.
                    </p>
                </>
            );
        }
    },
    {
        topic: "download-and-install",
        content: () => {
            return (
                <>
                    <h1>Download and install</h1>
                    <p>
                        StaticBackup Inc. provides 15-day free trial version of Backup9. You can download Backup9 for
                        free and try before you buy. To download install package, please click the download url{" "}
                        <a href="https://www.staticbackup.com/backup9/download">
                            https://www.staticbackup.com/backup9/download
                        </a>{" "}
                        and the download process will start soon. After download completed, please double-click on the
                        install package (DMG file) and drag drop those two icons to Application folder to complete the
                        installation.
                    </p>
                </>
            );
        }
    },
    {
        topic: "start-backup9",
        content: () => {
            return (
                <>
                    <h1>Start Backup9</h1>
                    <p>
                        If you are running Backup9 on the first-time, you need grant the "Full Disk Access" privilege.
                    </p>
                    <ul>
                        <li>
                            Click on "Apple" icon from the top left corner of system menu bar, and select "System
                            Preferences".
                        </li>
                        <li>Click on "Security & Privacy" icon.</li>
                        <li>
                            Select "Privacy" page, and on the left list please find "Full Disk Access" item then click
                            it.
                        </li>
                        <li>
                            On the right list you can click "+" to add Backup9GUI and Backup9Scheduler to the list, if
                            the button is gray and cannot be clicked, please unlock it by clicking the "Lock" button on
                            the bottom left corner.
                        </li>
                    </ul>
                    <p>If you need to add Backup9Scheduler app to the startup list</p>
                    <ul>
                        <li>Open "System Preferences" from the system drop down menu.</li>
                        <li>Click on "Users & Groups"</li>
                        <li>
                            Select the login account and on the right list, please click button "+" to add
                            Backup9Scheduler.
                        </li>
                        <li>On next startup of your system, the scheduler will run automatically.</li>
                    </ul>
                </>
            );
        }
    },
    {
        topic: "run-my-first-backup",
        content: () => {
            return (
                <>
                    <h1>Run my first backup</h1>
                    <p>
                        Before you start backup, please make sure you have closed all those application which have
                        contain the data you want to backup. Such as Firefox, Chrome, Safari, Thunderbird and etc.
                        Before the backup program will not able to read necessary files if these application are
                        running.
                    </p>
                    <p>
                        From the main-window of Backup9, you can click 'Backup' button to start backup. On the popup-up
                        dialog, it will prompt you to select which application data you want to backup. And then you
                        should choose an output filename for your new archive. You also need to decide the compression
                        level if you need to compress your archive and also setup a password if the archive should be
                        encrypted.
                    </p>
                    <h1>New backup</h1>
                    <p>
                        Make a new full backup means the backup program read and compress all files/registry items which
                        have found from your computer to an archive file. The backup program can generate an output
                        archive filename from current date/time for you, please view{" "}
                        <a href="/backup9/help/decide-the-archive-name">'Let the program decides the archive name'</a>
                    </p>
                    <h1>Backup to cloud storage</h1>
                    <p>
                        After the archive file is generated, you can also ask the backup program to upload your archive
                        to your cloud storage, to do this, the cloud storage should be setup before you start a backup
                        procedure. After the cloud storage setup, you can choose the cloud from the dropping menu from
                        "Target cloud".
                    </p>
                </>
            );
        }
    },
    {
        topic: "decide-the-archive-name",
        content: () => {
            return (
                <>
                    <h1>Let the program decides the archive name</h1>
                    <p>
                        Backup program can generate an output archive filename from current date/time for you. You need
                        to enable auto-generate option from preferences dialog. The program will generate the filename
                        for the new archive automatically once the auto-generate option is enabled on everytime you open
                        backup dialog. Which means you don't need to choose the folder and setup the filename everytime.
                    </p>
                    <h1>Why?</h1>
                    <p>
                        Because it will make life more easier. You don't need to enter or choose the output filename on
                        every backup time, just click Next button and wait.
                    </p>
                </>
            );
        }
    },
    {
        topic: "schedule-backup",
        content: () => {
            return (
                <>
                    <h1>Schedule backup</h1>
                    <p>
                        You can setup the scheduler for your backup plan. Please click Scheduler button from main-window
                        to open the scheduler setup dialog. Three pages will be found from scheduler setup dialog:
                        Scheduler, Source and Options.
                    </p>
                    <p>
                        On scheduler page, it allows you to enable or disable scheduler. And you also need to setup the
                        interval and startup time if the scheduler program is enabled.
                    </p>
                    <p>
                        The source page allows you to indicate which one or more items should be packed to new archive,
                        simply click "Select all" button to enable them all.
                    </p>
                    <p>
                        The options page contains all those options similar to backup options, you can choose the
                        compression level if you want to compress the archive and setup a new password if you also need
                        to encrypt the archive. You need to choose a folder for the new archive and you also can setup
                        the prefix/postfix for the filename. Choose a target cloud if you need to upload archive to
                        cloud storage after the archive is generated.
                    </p>
                    <p>
                        Please note: You need to ensure the scheduler app keeps running on your macOS system. There
                        should has an icon on the system menu bar, if it doesn't exist, you need to check the
                        installation and make sure the scheduler app is installed. If you need the scheduler app run on
                        the system startup, please add it to startup item list. You can check our help document for
                        futher help.
                    </p>
                </>
            );
        }
    },
    {
        topic: "backup-save-log-file",
        content: () => {
            return (
                <>
                    <h1>Save log file</h1>
                    <p>
                        After backup program finishes, you can save the backup log file and send it to us if you have
                        any question about your backup program. Scheduler backup will also save backup log file with the
                        archive file.
                    </p>
                </>
            );
        }
    },
    {
        topic: "open-an-archive-file",
        content: () => {
            return (
                <>
                    <h1>Restore your archive</h1>
                    <p>
                        To restore the backup archive, you need to click "Restore" button on main-window of Backup9. It
                        will open a dialog to let you choose the archive file you want to restore.
                    </p>
                    <p>
                        After the archive file is opened successfully, a restore dialog will popup and you can choose
                        which backup entries you want to restore.
                    </p>
                    <p>Dry run option allows you verify your archive without writing any data to your system.</p>
                </>
            );
        }
    },
    {
        topic: "restore-save-log-file",
        content: () => {
            return (
                <>
                    <h1>Save log file</h1>
                    <p>
                        You can always find 'Save log' button on each restore window/wizard, if you have any question
                        about the restore program, please save the log file and send to us.
                    </p>
                </>
            );
        }
    },
    {
        topic: "end-user-license-agreement",
        content: () => {
            return (
                <>
                    <h1>Software License Agreement</h1>
                    <ol>
                        <li>
                            This is an agreement between Licensor and Licensee, who is being licensed to use Backup9.
                        </li>
                        <li>
                            Licensee acknowledges that this is only a limited nonexclusive license. Licensor is and
                            remains the owner of all titles, rights, and interests in the Software.
                        </li>
                        <li>
                            This License permits Licensee to install the Software on more than one computer system, as
                            long as the Software will not be used on more than one computer system simultaneously.
                            Licensee will not make copies of the Software or allow copies of the Software to be made by
                            others, unless authorized by this License Agreement. Licensee may make copies of the
                            Software for backup purposes only.
                        </li>
                        <li>
                            This Software is subject to a limited warranty. Licensor warrants to Licensee that the
                            physical medium on which this Software is distributed is free from defects in materials and
                            workmanship under normal use, the Software will perform according to its printed
                            documentation, and to the best of Licensor's knowledge Licensee's use of this Software
                            according to the printed documentation is not an infringement of any third party's
                            intellectual property rights. This limited warranty lasts for a period of 20 days after
                            delivery. To the extent permitted by law, THE ABOVE-STATED LIMITED WARRANTY REPLACES ALL
                            OTHER WARRANTIES, EXPRESS OR IMPLIED, AND LICENSOR DISCLAIMS ALL IMPLIED WARRANTIES
                            INCLUDING ANY IMPLIED WARRANTY OF TITLE, MERCHANTABILITY, NONINFRINGEMENT, OR OF FITNESS FOR
                            A PARTICULAR PURPOSE. No agent of Licensor is authorized to make any other warranties or to
                            modify this limited warranty. Any action for breach of this limited warranty must be
                            commenced within one year of the expiration of the warranty. Because some jurisdictions do
                            not allow any limit on the length of an implied warranty, the above limitation may not apply
                            to this Licensee. If the law does not allow disclaimer of implied warranties, then any
                            implied warranty is limited to 20 days after delivery of the Software to Licensee. Licensee
                            has specific legal rights pursuant to this warranty and, depending on Licensee's
                            jurisdiction, may have additional rights.
                        </li>
                        <li>
                            In case of a breach of the Limited Warranty, Licensee's exclusive remedy is as follows:
                            Licensee will return all copies of the Software to Licensor, at Licensee's cost, along with
                            proof of purchase. (Licensee can obtain a step-by-step explanation of this procedure,
                            including a return authorization code, by contacting Licensor at [address and toll free
                            telephone number].) At Licensor's option, Licensor will either send Licensee a replacement
                            copy of the Software, at Licensor's expense, or issue a full refund.
                        </li>
                        <li>
                            Notwithstanding the foregoing, LICENSOR IS NOT LIABLE TO LICENSEE FOR ANY DAMAGES, INCLUDING
                            COMPENSATORY, SPECIAL, INCIDENTAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, CONNECTED
                            WITH OR RESULTING FROM THIS LICENSE AGREEMENT OR LICENSEE'S USE OF THIS SOFTWARE. Licensee's
                            jurisdiction may not allow such a limitation of damages, so this limitation may not apply.
                        </li>
                        <li>
                            Licensee agrees to defend and indemnify Licensor and hold Licensor harmless from all claims,
                            losses, damages, complaints, or expenses connected with or resulting from Licensee's
                            business operations.
                        </li>
                        <li>
                            Licensor has the right to terminate this License Agreement and Licensee's right to use this
                            Software upon any material breach by Licensee.
                        </li>
                        <li>
                            Licensee agrees to return to Licensor or to destroy all copies of the Software upon
                            termination of the License.
                        </li>
                        <li>
                            This License Agreement is the entire and exclusive agreement between Licensor and Licensee
                            regarding this Software. This License Agreement replaces and supersedes all prior
                            negotiations, dealings, and agreements between Licensor and Licensee regarding this
                            Software.
                        </li>
                        <li>
                            This License Agreement is governed by the law of New York applicable to New York contracts.
                        </li>
                        <li>
                            This License Agreement is valid without Licensor's signature. It becomes effective upon the
                            earlier of Licensee's signature or Licensee's use of the Software.
                        </li>
                    </ol>
                </>
            );
        }
    },
    {
        topic: "enter-register-name-key",
        content: () => {
            return (
                <>
                    <h1>Enter register name/key</h1>
                    <p>
                        After you have placed an order of Backup9, you will receive your registration information within
                        1 hour, please enter the register name and key to the register-form to register it. Make sure
                        you have deleted all empty spaces around the name and key before you click Register button.
                    </p>
                </>
            );
        }
    },
    {
        topic: "crash-report",
        content: () => {
            return (
                <>
                    <h1>Crash report</h1>
                    <p>
                        If Backup9 crashes, a crash collector program will popup, it will ask you to save the crash dump
                        file to computer, please save the file and send it to us, we will help you to find why the
                        program crashes on your computer. Send the file to{" "}
                        <a href="mailto:support@staticbackup.com">support@staticbackup.com</a>
                    </p>
                </>
            );
        }
    },
    {
        topic: "log-report",
        content: () => {
            return (
                <>
                    <h1>Log report</h1>
                    <p>
                        If you have any question about the backup or restore, please send the log file to us, and we
                        will help you to find what happend on your computer. Send the file to{" "}
                        <a href="mailto:support@staticbackup.com">support@staticbackup.com</a>
                    </p>
                </>
            );
        }
    }
];

function Backup9Help() {
    return <Help uri="/backup9/help" topics={topics} sidebar={Sidebar} />;
}

export default Backup9Help;
