import React from "react";
import "./Backup8Feedback.scss";

function Backup8Feedback() {
    return (
        <div className="Section Backup8Feedback-bg">
            <div className="Content Backup8Feedback">
                <h3 className="Quotes">Thanks so much for your help and your determination to solve the problem despite the fact that it was in all probability a fault with my computer and not with your software, which seems to be a very good piece of software writing. It worked well and saved all that I asked it to and was straightforward to use.</h3>
                <h1 className="RightText">Perry</h1>
            </div>
        </div>
    )
}

export default Backup8Feedback;
