import ImgBecky from "./backup8imgs/becky.png";
import ImgChrome from "./backup8imgs/chrome.png";
import ImgFirefox from "./backup8imgs/firefox.png";
import ImgFoxmail from "./backup8imgs/foxmail.png";
import ImgIE from "./backup8imgs/ie.png";
import ImgIncredimail from "./backup8imgs/incredimail.png";
import ImgOutlookExpress from "./backup8imgs/outlook-express.png";
import ImgOutlook2003 from "./backup8imgs/outlook2003.png";
import ImgSafari from "./backup8imgs/safari.png";
import ImgThebat from "./backup8imgs/thebat.png";
import ImgThunderbird from "./backup8imgs/thunderbird.png";
import ImgWindowsLiveMail from "./backup8imgs/windows-live-mail.png";
import ImgWindowsMail from "./backup8imgs/windows-mail.png";

export const SUPPORT_FILES = [
    { name: "Internet Explorer", img: ImgIE },
    { name: "Chrome", img: ImgChrome },
    { name: "Firefox", img: ImgFirefox },
    { name: "Safari", img: ImgSafari },
    { name: "Foxmail", img: ImgFoxmail },
    { name: "Incredimail", img: ImgIncredimail },
    { name: "Thunderbird", img: ImgThunderbird },
    { name: "Windows Live Mail", img: ImgWindowsLiveMail },
    { name: "Windows Mail", img: ImgWindowsMail },
    { name: "Outlook Express", img: ImgOutlookExpress },
    { name: "Outlook 2003", img: ImgOutlook2003 },
    { name: "Becky", img: ImgBecky },
    { name: "Thebat", img: ImgThebat }
];
