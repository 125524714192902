import React from "react";
import "./ButtonPanel.scss";

import Button from "./Button";

export function More(props) {
    const { url, pad } = props;
    const clsName = pad ? "More" : "More-nopad";
    if (url) {
        return (
            <div className={clsName}>
                <a href={url}>Learn more</a><span className="More-brace">></span>
            </div>
        );
    } else {
        return (<></>)
    }
}

function ButtonPanel(props) {
    const { caption, url, urlMore, dir } = props;
    const clsName = dir === "column" ? "ButtonPanel-column" : "ButtonPanel";
    const pad = dir !== "column";
    return (
        <div className={clsName}>
            <Button caption={caption} url={url} />
            <More url={urlMore} pad={pad} />
        </div>
    );
}

export default ButtonPanel;
