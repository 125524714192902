import React from "react";
import "./Backup8Desc.scss";

import FeatureIcon from "./FeatureIcon";

function Backup8Desc() {
    return (
        <div className="Section Backup8Desc-bg">
            <div className="Content Backup8Desc">
                <h1>What can Backup8 do?</h1>
                <p>It is very difficule to find all those messages, contacts, plugins, settings, favorites from your computer and backup them before you erase your system from your computer. Backup8 will help you to do this job. Just only need few clicks Backup8 will find and backup all necessary personal data from your computer, such as messages, contacts, plugins (from Firefox, Thunderbird), settings (from registry) and favorites to one archive file with high ratio compression. Upgrade system, transfer personal data to another computer or reinstall system now is easy.</p>
                <div className="Backup8Desc-features">
                    <div className="Backup8Desc-feature-entry">
                        <div className="Backup8Desc-feature-entry-title"><FeatureIcon /><h3>Easy to backup or restore</h3></div>
                        <p>
                            With ribbon layout, you don't need to learn how to use it, just few clicks will get all your data backed up. Backup8 allows you store more data above 4GB. Also the output archive will be compressed with high ratio algorithm. Archive Explorer has built in. Easy to browse your archive file and easy to restore your data.
                        </p>
                    </div>
                    <div className="Backup8Desc-feature-entry">
                        <div className="Backup8Desc-feature-entry-title"><FeatureIcon /><h3>Scheduler has built-in</h3></div>
                        <p>
                            Scheduler is a service program, after you install backup8, the scheduler service will start with your computer starts up. You can setup scheduler and adjust backup options from the main-window of Backup8. After you enable the scheduler, your backup archive will be created in time. You can setup scheduler program by hourly, daily, weekly and monthly.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Backup8Desc;
