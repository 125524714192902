import React from "react";

function PayVisa() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="18" viewBox="0 0 28 18">
            <g fill="none" fillRule="evenodd">
                <rect width="28" height="18" fill="#2B2771" fillRule="nonzero" rx="2" />
                <path
                    fill="#FFF"
                    d="M13.7108053,10.762135 C13.6984378,9.78705598 14.5797843,9.24288637 15.2437233,8.91937884 C15.925888,8.58740934 16.1550121,8.3745583 16.1524084,8.07773852 C16.147201,7.62339595 15.6082388,7.4229124 15.1037753,7.41510136 C14.2237307,7.40143203 13.7121071,7.65268737 13.3052818,7.84275618 L12.9882834,6.35930816 C13.3964106,6.17119211 14.1521294,6.00716013 14.9358378,6 C16.7753394,6 17.9788916,6.90803422 17.9854008,8.31597545 C17.9925609,10.1027525 15.5138553,10.2016924 15.5307792,11.000372 C15.5366375,11.2425144 15.7677143,11.5009299 16.2741306,11.5666729 C16.524735,11.5998698 17.2166636,11.6252557 18.0010229,11.2639948 L18.3089083,12.6992747 C17.8871118,12.8528919 17.3448949,13 16.6698903,13 C14.9384415,13 13.7205691,12.0795983 13.7108053,10.762135 M21.2673424,12.8763251 C20.9314674,12.8763251 20.6483169,12.680398 20.5220383,12.3796727 L17.8942719,6.10544914 L19.7324716,6.10544914 L20.098289,7.11632881 L22.344616,7.11632881 L22.5568161,6.10544914 L24.1769574,6.10544914 L22.7631579,12.8763251 L21.2673424,12.8763251 M21.524456,11.0472382 L22.0549563,8.50474242 L20.6021015,8.50474242 L21.524456,11.0472382 M11.4820532,12.8763251 L10.033104,6.10544914 L11.7847313,6.10544914 L13.2330296,12.8763251 L11.4820532,12.8763251 M8.89073833,12.8763251 L7.06750976,8.26780733 L6.33001674,12.1863493 C6.2434443,12.6237679 5.90171099,12.8763251 5.52222429,12.8763251 L2.54165892,12.8763251 L2.5,12.6797471 C3.11186535,12.5469593 3.80704854,12.3328064 4.22819416,12.1036824 C4.48595871,11.9637344 4.55951274,11.8413614 4.64413242,11.5087409 L6.041008,6.10544914 L7.89222615,6.10544914 L10.7302399,12.8763251 L8.89073833,12.8763251"
                    transform="matrix(1 0 0 -1 0 19)"
                />
            </g>
        </svg>
    );
}

export default PayVisa;
