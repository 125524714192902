import React from "react";
import "./StaticSyncBrief.scss";

import { isMacOS } from "./Consts";

import StaticSyncIcon from "./StaticSyncIcon.png";

function StaticSyncBrief() {
    const clsName = isMacOS() ? "Content StaticSyncBrief_macOS" : "Content StaticSyncBrief";
    return (
        <div className="Section StaticSyncBrief-bg">
            <div className={clsName}>
                <div className="StaticSyncBrief-title">
                    <img className="ProductLogoBigger" src={StaticSyncIcon} alt="StaticSync" />
                    <h1>
                        StaticSync
                        <br />
                        <small>Synchronize files to clouds precise control, efficiency</small>
                    </h1>
                </div>
                <p>
                    A powerful file synchronization tool that uses it to sync files from anywhere to the cloud,
                    precisely control the synchronization content, determine conflict handling before synchronization,
                    and no longer require original cloud client support.
                </p>
                <p>
                    Every day we need to deal with various documents, Excel, PPT, Word, etc. Sometimes we need to
                    transfer these documents between different locations. We may use USB disk as a storage medium or
                    synchronize them through the cloud storage. So, we often encounter a problem, which documents are
                    the latest? And which documents are old? If you synchronize these files frequently, you will
                    inevitably use the old files as new files for a moment of negligence, so the loss is sometimes
                    immeasurable. StaticSync is designed to help you synchronize files and avoid inadvertently
                    overwriting useful files.
                </p>
            </div>
        </div>
    );
}

export default StaticSyncBrief;
