import React from "react";

import { SupportArea } from "./Common";

function StaticSyncDocument(props) {
    const { hi } = props;
    return <SupportArea urlHelp="/staticsync/help" urlFAQ="/staticsync/faq" hi={hi} />;
}

export default StaticSyncDocument;
