import React, { useState } from "react";
import "./StaticSyncDownloads.scss";

import Button from "./Button";

import {
    STATICSYNC_APPSTORE_URL,
    STATICSYNC_MICROSOFTSTORE_URL,
    DOWNLOAD_URL_WINDOWS,
    DOWNLOAD_URL_MAC,
    isMacOS
} from "./Consts";

import LogoMac from "./LogoMac.png";
import LogoWindows from "./LogoWindows.png";
import LogoMacGray from "./LogoMacGray.png";
import LogoWindowsGray from "./LogoWindowsGray.png";

import AppStoreLogo from "./AppStoreLogo.png";
import MicrosoftStoreLogo from "./MicrosoftStoreLogo.png";

const downloads = [
    {
        title: "Download for Windows",
        url: DOWNLOAD_URL_WINDOWS,
        os: "windows",
        img: LogoWindows,
        grayImg: LogoWindowsGray,
        store: {
            logo: MicrosoftStoreLogo,
            url: STATICSYNC_MICROSOFTSTORE_URL
        }
    },
    {
        title: "Download for macOS",
        url: DOWNLOAD_URL_MAC,
        os: "mac",
        img: LogoMac,
        grayImg: LogoMacGray,
        store: {
            logo: AppStoreLogo,
            url: STATICSYNC_APPSTORE_URL
        }
    }
];

function StaticSyncDownloads() {
    const [download, setDownload] = useState(isMacOS() ? downloads[1] : downloads[0]);
    const osButtonClicked = event => {
        const { id } = event.target;
        for (const download of downloads) {
            if (id === download.os) {
                setDownload(download);
                break;
            }
        }
        console.log(event.target);
    };
    return (
        <div id="download" className="Section StaticSyncDownloads-bg">
            <div className="Content StaticSyncDownloads">
                <h1>Downloads</h1>
                <div className="StaticSyncDownloads-container">
                    <div className="StaticSyncDownloads-os-list">
                        {downloads.map((loopDownload, key) => {
                            const selected = loopDownload.os === download.os;
                            const clsName = selected ? "StaticSyncDownloads-os-selected" : "StaticSyncDownloads-os";
                            const img = selected ? loopDownload.img : loopDownload.grayImg;
                            return (
                                <div key={key} className={clsName}>
                                    <img
                                        id={loopDownload.os}
                                        src={img}
                                        alt={loopDownload.title}
                                        width={30}
                                        height={30}
                                        onClick={osButtonClicked}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <p>
                        Using StaticSync can easily synchronize your files to different cloud storages, create multiple
                        projects, and bind your various types of cloud storage accounts without restrictions, making
                        your synchronization work easy and quick.
                    </p>
                    <div className="StaticSyncDownloads-button-container">
                        <Button big caption={download.title} url={download.url} />
                    </div>
                    <p className="StaticSyncDownloads-download-desc">
                        <span className="Bold">Version 1.2</span>
                        <br />
                        <span className="RedText">&nbsp;*&nbsp;</span>Please switch off GoogldDrive, OneDrive, Dropbox
                        clients to avoid conflicts.
                    </p>
                    <div className="StaticSyncDownload-store">
                        <span className="Bold">Also available in</span>
                        <a href={download.store.url}>
                            <img src={download.store.logo} width={200} alt="" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StaticSyncDownloads;
