import React from "react";
import { Link } from "react-router-dom";

import Help from "./Help";

import InstMacOSImg from "./InstallationMacOS.jpg";

function Sidebar() {
    return (
        <div className="Help-sidebar">
            <h1>What is StaticSync?</h1>
            <p><Link to="/staticsync/help/welcome">Welcome</Link></p>
            <p><Link to="/staticsync/help/whatisstaticsync">A powerful synchronize tool</Link></p>
            <h1>Download and install</h1>
            <p><Link to="/staticsync/help/download">Download</Link></p>
            <p><Link to="/staticsync/help/instmac">Install on MacOS</Link></p>
            <p><Link to="/staticsync/help/instwin">Install on Windows</Link></p>
            <h1>How to use?</h1>
            <h4>Main-window</h4>
            <p><Link to="/staticsync/help/mainwindow">Overview</Link></p>
            <p><Link to="/staticsync/help/createproject">Create your project</Link></p>
            <p><Link to="/staticsync/help/deleteproject">Delete your project</Link></p>
            <p><Link to="/staticsync/help/setupproxy">Setup a proxy server</Link></p>
            <p><Link to="/staticsync/help/configclouds">Config your clouds</Link></p>
            <h4>Project-inspector</h4>
            <p><Link to="/staticsync/help/projectinspector">Overview</Link></p>
            <p><Link to="/staticsync/help/projectsettings">Project settings</Link></p>
            <h1>Purchase and register</h1>
            <p><Link to="/staticsync/help/trial">Trial period</Link></p>
            <p><Link to="/staticsync/help/register">Register</Link></p>
            <h1>Support</h1>
            <p><Link to="/staticsync/help/contactus">Contact us</Link></p>
        </div>
    )
}

function Welcome() {
    return (
        <>
            <h1>Welcome to the help document of StaticSync</h1>
            <p>StaticSync is a powerful utility and it is very easy to use. The manual will show all the details that how to synchronize your files.</p>
            <p>You might need to learn these topics to understand that how to use StaticSync</p>
            <ul>
                <li>Install StaticSync to your system</li>
                <li>Authorize the access privileges of your cloud account</li>
                <li>Create a new project</li>
                <li>Understand the actions which are generated automatically</li>
                <li>Solve the conflicts</li>
            </ul>
        </>
    );
}

function WhatIsStaticSync() {
    return (
        <>
            <h1>Why use StaticSync?</h1>
            <p>Every day we need to deal with various documents, Excel, PPT, Word, etc. Sometimes we need to transfer these documents between different locations. We may use USB disk as a storage medium or synchronize them through the cloud storage. So, we often encounter a problem, which documents are the latest? And which documents are old? If you synchronize these files frequently, you will inevitably use the old files as new files for a moment of negligence, so the loss is sometimes immeasurable. StaticSync is designed to help you synchronize files and avoid inadvertently overwriting useful files.</p>
            <h1>Key features</h1>
            <ul>
                <li>Easily monitor all file changes</li>
                <li>The program will automatically handle most file changes and automatically determine which items need to be uploaded or downloaded.</li>
                <li>Select conflicting files and choose a strategy</li>
                <li>Once the selection strategy is complete, all files can be synchronized with just one click</li>
                <li>View all sync history and keep track of file changes</li>
                <li>Support for filters to include or exclude specific types of files</li>
                <li>You can use a proxy server to transfer data to the cloud</li>
            </ul>
        </>
    );
}

function Download() {
    return (
        <>
            <h1>Download the installation package for your operating system.</h1>
            <p>To download the installation package for your operating system just visit our <Link to="/download">Download page</Link>, The website will automatically detect your operating system and prepare the corresponding installation package.</p>
            <h1>Or you can download the installation package</h1>
            <ul>
                <li><Link to="/download/windows">For Windows platform</Link></li>
                <li><Link to="/download/mac">For MacOS platform</Link></li>
            </ul>
        </>
    );
}

function InstallOnWindows() {
    return (
        <>
            <h1>Install StaticSync on Windows</h1>
            <p>Double-click the downloaded installation file StaticSync.exe and it will be installed using the installation wizard. Please follow the steps. After completing the installation steps, StaticSync will be installed on your Windows system. You can find the StaticSync icon in the Start menu, or you can type "StaticSync" after pressing the Start button.</p>
            <p className="Help-specific-info">Your Windows may prompt that the program may be unsafe. This is because we have not signed the installation package yet, please select "Continue Anyway".</p>
            <p>Please note that for the first time we recommend that you select "Run as administrator" to run StaticSync, because StaticSync needs to explain to the system that StaticSync needs to receive a specific cloud authorization request (this request will be initiated when you need to use the cloud), if You are not using "Run as administrator", then StaticSync will display an error at startup, telling you that it is temporarily unable to receive cloud authorization, and if you need to configure cloud services, you will face failure.</p>
        </>
    )
}

function InstallOnMacOS() {
    return (
        <>
            <h1>Install StaticSync on MacOS</h1>
            <p>Installing StaticSync on MacOS Just like any other application, double-click to open the dmg file and you will see an installation window similar to the one below.</p>
            <p>Follow the instructions in the window and drag the icon of StaticSync to the Applications folder to complete the installation.</p>
            <p><img width={500} src={InstMacOSImg} alt="" /></p>
        </>
    )
}

function MainWindow() {
    return (
        <>
            <h1>Main-window</h1>
            <p>We have designed the main interface of StaticSync and hope to design the main window as simple as possible. In the main window interface, it is mainly divided into two parts, the main toolbar and the Project list.</p>
            <h1>Toolbar</h1>
            <p>There are three types of buttons on the main toolbar, one for Project related, for creating, deleting and opening Project-inspector related to Project, and the other for synchronizing all Project-related files and re-analysing Project-related files. Files, and finally there is a button to open the cloud settings window.</p>
            <h1>Project list</h1>
            <p>The Project-list will list all the created Projects, and you will find some basic details on each Project's regulations. For example, under the Project name, there will be information about the total number of files and the total size of the Project. The number of Conflicts will be listed in particular. You can decide whether you need to open the Project-inspector for processing. If the current Project is being analyzed or synced, you will also see [analyzing] or [syncing] under the current Project name.</p>
        </>
    )
}

function CreateProject() {
    return (
        <>
            <h1>How to create a new project?</h1>
            <p>The first time you open the main program of StaticSync you will see a row of toolbars and an empty list box. Clicking the first button "New project" in the toolbar opens the wizard for creating a Project.</p>
            <ol>
                <li>First you need to enter a name for your new Project. This name cannot be duplicated with other Project names, it will be used to identify each of your individual projects. After entering the name, click the "Next" button to continue.</li>

                <li>At this point you need to select the folder corresponding to Project. You need to select two folders to represent Local and Remote respectively. StaticSync will synchronize files in these two folders. Remote location can choose your cloud storage (note that if you want to use cloud storage as your remote location, then you need to configure cloud storage authorization first, please see the section "Configuring Cloud Storage Permissions"). After the location selection is complete, you need to select the synchronization direction. "Upload" means that the changes in the Local location are synchronized to the Remote location, and "Download" means that the changes in the Remote location are synchronized to the Local location. The choice of both means that the changes on both sides will be synchronized with each other. After the configuration is complete, click the "Next" button to continue.</li>
                <li>You can choose to add specific filters to include or Exclude some files to determine what you want to sync. These filters use the wildcard string to distinguish between those special files.</li>
                <li>Press the "Finish" button to create this new Project.</li>
            </ol>
            <p>After you press the Finish button, the wizard for the new Project will be closed, the new project will appear in the list in the main window, and you will find that it is being scanned to analyze the files to be synchronized.</p>
        </>
    )
}

function DeleteProject() {
    return (
        <>
            <h1>Delete a project</h1>
            <p>Deleting a Project is very simple. You only need to select the Project you want to delete in the main window, and then click the Delete button to confirm the deletion. When the Project is deleted, all related windows will be closed and all related files will be deleted.</p>
        </>
    )
}

function SetupProxy() {
    return (
        <>
            <h1>Setup a proxy server</h1>
            <p>You can set up one or more proxy servers and then use them for access to your cloud storage.</p>
            <h1>Add a Proxy server</h1>
            <p>First, you need to open the Preferences dialog by selecting the menu "File" -> "Preferences". In the Preferences dialog, select the second page "Network". At the bottom of this page, you will see a group called proxy server.</p>
            <p>Click the "Add" button on the right to configure the information of your proxy server in the pop-up window. If you don't know what these mean, please ask the person who provided the proxy server to you. They will guide you how to fill in those values.</p>
            <h1>Set the proxy server to your cloud storage</h1>
            <p>After you have added the proxy server, press the "Ok" button to save the proxy server to preferences, then press the "Clouds" button in the main window to open the cloud settings.</p>
            <p>Select any cloud storage page. At the top of the page, select a proxy server and check the Check-box in front of it, then press "Ok" to save the settings and exit the cloud storage window.</p>
            <p>At this time, when StaticSync accesses the cloud storage, it will use the proxy server to access.</p>
        </>
    )
}

function ConfigClouds() {
    return (
        <>
            <h1>Why I need to config a cloud account?</h1>
            <p>If you don't have any cloud account configured, then you can only sync files locally. If you want to sync files with the cloud, then you first need to configure a cloud account. If you already have a cloud account, it is easy to configure them in StaticSync. If not, please go to the corresponding cloud website to register for an account. Generally, there is a certain amount of free space for you to use. For example, you can register an account of Dropbox or OneDrive. Therefore, we assume that you want to configure an existing cloud account.</p>
            <h1>Select a cloud type</h1>
            <p>Click the "cloud" button in the main window. In the window that opens, you will see a "Local" item that exists by default. This item indicates that you can use the local drive as the synchronization target by default.</p>
            <p>Click the "+" button below this list. A window will pop up to let you choose the type of cloud. You can choose Dropbox, OneDrive or other cloud types. Double-click on this list or select it and click the "ok" button to continue.</p>
            <h1>Login to your account on your default browser</h1>
            <p>A wizard will appear, which will help you authorize a cloud account for StaticSync. After clicking the "next" button, we open the verification page. Three seconds later, StaticSync will open your default browser and lead you to the corresponding cloud authorization page you selected earlier. Please enter your account in the browser and log in (note: these operations will be completed on the cloud server, StaticSync will not get your account information).</p>
            <p>After the login is complete, the browser will jump back to StaticSync. Soon StaticSync will obtain the basic information of your cloud storage through your authorization. At this time, the cloud configuration operation has been completed and you will see the new one after you close the wizard. Cloud storage information already exists in the list on the left.</p>
            <h1>Config more cloud account</h1>
            <p>To configure a new cloud account, please continue to click the "+" sign to configure, or you can press the "ok" button to continue. Your cloud account configuration is now complete.</p>

        </>
    )
}

function ProjectInspector() {
    return (
        <>
            <h1>Use Project-inspector to manage your project</h1>
            <p>The Project-inspector is used to check the current synchronization status of your Project. You can view the changed files and the actions that StaticSync will take here. At the same time, you also need to decide here what actions should be taken for some conflicting files.</p>
            <p>At the same time, you can view all the history of this Project so that you can see what happened in the past.</p>
            <h1>Synchronize</h1>
            <p>When StaticSync analyzes all the files associated with the Project, it lists all the files in the file list and the comparison of these files in two different locations. Before syncing, you can see how StaticSync is ready to sync your files by looking at this list of files.</p>
            <p>In the file list, the operations of synchronization include the following:</p>
            <ul>
                <li><strong>Upload</strong> - upload the file located in the Local location to the corresponding path of the remote location.</li>
                <li><strong>Download</strong> - Download the file located in the Remote location to the corresponding path of the Local location.</li>
                <li><strong>Delete from local</strong> - delete the file from the Local location.</li>
                <li><strong>Delete from remote</strong> - delete the file from the remote location.</li>
                <li><strong>Do nothing</strong> - the file does not need to be synchronized.</li>
                <li><strong>Conflict</strong> - The file has been changed in both locations (possibly deleted or possibly modified). You need to decide how to synchronize this file.</li>
            </ul>
            <p>You can modify the specific synchronization method by double-clicking the Action bar in the file list, or after selecting multiple file items, use the buttons in the toolbar to set the specific synchronization method.</p>
            <p>Before you start syncing, you need to set the action of all file items marked as `conflict` to other values. If you are unsure or don't want to handle it in this synchronization, you can set it to Ignore.</p>
            <h1>Refresh</h1>
            <p>Pressing the Refresh button will cause StaticSync to re-analyze all files associated with the Project, and the values in the file list and action column will be reset.</p>
            <h1>File-view</h1>
            <p>Column and description</p>
            <ul>
                <li><strong>Path</strong> - the path of the file relative to the Project root directory.</li>
                <li><strong>Local</strong> - the size of the file in the Local location.</li>
                <li><strong>Sha1 (Local)</strong> - the sha1 checksum of the file at the local location.</li>
                <li><strong>Remote</strong> - the size of the file in the remote location.</li>
                <li><strong>Sha1 (Remote)</strong> - the sha1 checksum of the file at the remote location.</li>
                <li><strong>Action</strong> - the action that StaticSync will perform on the file in sync.</li>
            </ul>
            <h1>History-view</h1>
            <p>In the History category of the sidebar, all the synchronization histories will be listed. Each synchronization will generate a history record. Clicking on the corresponding history item will display the detailed file operation of the synchronization on the right side.</p>
            <p>Column and description</p>
            <ul>
                <li><strong>Action</strong> - the action taken.</li>
                <li><strong>Name</strong> - the name of the file.</li>
                <li><strong>Path</strong> - the location of the file (relative to the root directory of Project).</li>
                <li><strong>Occured at</strong> - Occurrence time.</li>
            </ul>
        </>
    )
}

function ProjectSettings() {
    return (
        <>
            <h1>Project settings</h1>
            <p>Setting your Project's options is the same as creating a Project. Click the "Settings" button from the Project-inspector window to open the current Project settings window. You can modify anything related to the Project, even the name, you just need to be careful not to use the same name as the existing project.</p>
            <p>It's worth noting that in the Project settings window, there is a group called History, you can use the "Clear" button in this group to clear all the history information. If you use this button to clear all historical information, the historical information corresponding to the project will be lost, and the files associated with the project will be re-evaluated and compared.</p>
        </>
    )
}

function Trial() {
    return (
        <>
            <h1>Trial period</h1>
            <p>Currently, StaticSync offers a 10-day full-featured trial period. During this time, all features are open and fully available to you. You can try it out before you decide whether you want to buy StaticSync.</p>
        </>
    )
}

function Register() {
    return (
        <>
            <h1>Register</h1>
            <p>When you complete the purchase, our back-end system will automatically generate a registration code for you. With this registration code, you can close the trial period of StaticSync and enter the official use time. Please keep your registration code safe and don't leak it to others. If you have forgotten your registration code, please send an email to <a href="mailto:register@staticbackup.com">register@staticbackup.com</a> and we will send you your registration code within 24 hours.</p>
        </>
    )
}

function ContactUs() {
    return (
        <>
            <h1>Contact us</h1>
            <p>If you have any questions or suggestions during the use of the software, please send an email to <a href="mailto:support@staticbackup.com">support@staticbackup.com</a> and we will respond to you within 24 hours. We sincerely look forward to your comments and suggestions.</p>
        </>
    )
}

const topics = [
    {
        topic: "welcome",
        content: Welcome
    },
    {
        topic: "whatisstaticsync",
        content: WhatIsStaticSync
    },
    {
        topic: "download",
        content: Download
    },
    {
        topic: "instwin",
        content: InstallOnWindows
    },
    {
        topic: "instmac",
        content: InstallOnMacOS
    },
    {
        topic: "mainwindow",
        content: MainWindow
    },
    {
        topic: "createproject",
        content: CreateProject
    },
    {
        topic: "deleteproject",
        content: DeleteProject
    },
    {
        topic: "setupproxy",
        content: SetupProxy
    },
    {
        topic: "configclouds",
        content: ConfigClouds
    },
    {
        topic: "projectinspector",
        content: ProjectInspector
    },
    {
        topic: "projectsettings",
        content: ProjectSettings
    },
    {
        topic: "trial",
        content: Trial
    },
    {
        topic: "register",
        content: Register
    },
    {
        topic: "contactus",
        content: ContactUs
    }
];

function StaticSyncHelp() {
    return (
        <Help uri="/staticsync/help" topics={topics} sidebar={Sidebar} />
    );
}

export default StaticSyncHelp;
