import React from "react";
import "./StaticSyncSupportClouds.scss";

import OneDrive from "./OneDriveLogo.png";
import DropboxLogo from "./DropboxLogo.png";
import GoogleDriveLogo from "./GoogleDriveLogo.png";

const clouds = [
    {
        name: "OneDrive",
        icon: OneDrive
    },
    {
        name: "Dropbox",
        icon: DropboxLogo
    },
    {
        name: "GoogleDrive",
        icon: GoogleDriveLogo
    }
];

function renderCloudItem(cloud, key) {
    const { name, icon } = cloud;
    return (
        <img key={key} src={icon} alt={name} />
    );
}

function StaticSyncSupportClouds() {
    return (
        <div className="Section StaticSyncSupportClouds-bg">
            <div className="Content StaticSyncSupportClouds">
                <h1>Support for popular cloud storage</h1>
                <p>We support popular cloud storage. You can easily sync your local files to different cloud storage. You don't need to worry about your privacy being leaked because our program uses the standard auth2 interface to access your cloud storage data, you can cancel StaticSync access to your account at any time in cloud storage management.</p>
                <div className="StaticSyncCloudLogos">
                    {
                        clouds.map((cloud, key) => {
                            return renderCloudItem(cloud, key);
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default StaticSyncSupportClouds;
