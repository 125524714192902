import React from "react";
import "./Backup8SupportsSoftware.scss"

import { SUPPORT_FILES } from "./Backup8Common";

function Backup8SupportsSoftware() {
    return (
        <div className="Section Backup8SupportsSoftware-bg">
            <div className="Content Backup8SupportsSoftware">
                <h1>Backup8 supports most popular software</h1>
                <p>This powerful backup tool supports most popular software and will find, backup all personal data and settings. It also will backup 'My Documents' folder or any folder or registry item you have added to custom list. Backup8 can backup data from Windows Live Mail, Outlook 2003, Outlook 2007, Outlook 2010, Windows Mail, Outlook Express, Firefox, Thunderbird, Incredimail.</p>
                <div className="Backup8SupportList">
                    {
                        SUPPORT_FILES.map((entry, key) => {
                            return (
                                <div key={key} className="Backup8SupportListEntry">
                                    <img src={entry.img} alt="Supported software" />
                                    <span className="BoldText SmallText">{entry.name}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Backup8SupportsSoftware;
