import Screenshot01 from "./StaticSyncScreenshot01.png";
import Screenshot02 from "./StaticSyncScreenshot02.png";
import Screenshot03 from "./StaticSyncScreenshot03.png";
import Screenshot04 from "./StaticSyncScreenshot04.png";
import Screenshot05 from "./StaticSyncScreenshot05.png";
import Screenshot06 from "./StaticSyncScreenshot06.png";
import Screenshot07 from "./StaticSyncScreenshot07.png";

import Screenshot01Win from "./StaticSyncScreenshot01_win.png";
import Screenshot02Win from "./StaticSyncScreenshot02_win.png";
import Screenshot03Win from "./StaticSyncScreenshot03_win.png";
import Screenshot04Win from "./StaticSyncScreenshot04_win.png";
import Screenshot05Win from "./StaticSyncScreenshot05_win.png";
import Screenshot06Win from "./StaticSyncScreenshot06_win.png";
import Screenshot07Win from "./StaticSyncScreenshot07_win.png";

export const StaticSyncScreenshots = [
    {
        title: "Easy to manage all your projects",
        desc:
            "You can add, edit and remove a project easily. StaticSync will find all differences and mark the different number to the main window. With clicking `synchronize` button you can easily synchronize all changes between local storages and remote storages.",
        src: Screenshot01,
        srcWin: Screenshot01Win
    },
    {
        title: "Inspect your project",
        desc:
            "Double click on a project item from main window will open the project inspector. Explore all files, differences from the project window and inspect the changes. The history of the project are also be recorded and it also can be found from the project window.",
        src: Screenshot02,
        srcWin: Screenshot02Win
    },
    {
        title: "File filters",
        desc:
            "You can adjust the project settings and manage your filters. Use filter to determine which file should be synchronized or not. You can use wildcard characters in your filter string, for example, you can add a filter `*.jpg` with type include to synchronize all your JPEG files. ",
        src: Screenshot03,
        srcWin: Screenshot03Win
    },
    {
        title: "Manage cloud storage",
        desc:
            "Use cloud storage manager to authorize the access privileges of your cloud storages. More cloud storage products will be supported in nearly future.",
        src: Screenshot04,
        srcWin: Screenshot04Win
    },
    {
        title: "Support proxy",
        desc:
            "StaticSync supports proxy server, you can pass your data through a proxy server to visit your cloud. You can define one or more proxy servers and choose one of them from the cloud dialog to apply it. Proxy server makes your network access more security and safety.",
        src: Screenshot05,
        srcWin: Screenshot05Win
    },
    {
        title: "Solve conflict",
        desc:
            "If your file has been modified from both side (local and remote), the file will be marked as `conflict`, you can decide what action should be taken for the file from project window. It will ensure that no file will be delted or overwritten by accident.",
        src: Screenshot06,
        srcWin: Screenshot06Win
    },
    {
        title: "Thank you for choosing StaticSync",
        desc:
            "You can send your suggestions, question to us. We will reply you as soon as possible. All your suggestions are valuable and we're hearing you.",
        src: Screenshot07,
        srcWin: Screenshot07Win
    }
];
