import React, { useState } from "react";
import "./PurchasePage.scss";

import Button from "./Button";

import LogoMac from "./LogoMac.png";
import LogoWindows from "./LogoWindows.png";

import { BUY_URL, BUY_2_URL, BUY_5_URL } from "./Consts";

import { PurchaseCards, PurchasePanel, PurchaseBenefit } from "./Common";

import StaticSyncIcon from "./StaticSyncIcon.png";


const StaticSyncBuyTypes = [
    {
        title: "Lifetime",
        url: BUY_5_URL,
        price: {
            major: "29",
            postfix: ".95"
        }
    },
    {
        title: "1 Year",
        url: BUY_URL,
        price: {
            major: "9",
            postfix: ".95"
        }
    },
    {
        title: "2 Year",
        url: BUY_2_URL,
        price: {
            major: "17",
            postfix: ".95"
        }
    }
];

export function PurchaseStaticSyncPage() {
    const [buyType, setBuyType] = useState(StaticSyncBuyTypes[0]);
    const onTypeChanged = event => {
        const newType = StaticSyncBuyTypes[event.target.value];
        setBuyType(newType);
    };
    return (
        <div className="Section PurchasePage-bg">
            <div className="Content PurchasePage">
                <div className="PurchasePage-product-top">
                    <h1 className="PurchasePage-title">
                        PURCHASE&nbsp;
                        <img src={StaticSyncIcon} width={40} alt="StaticSync" />
                        &nbsp;
                        <span className="RedText">StaticSync</span>
                    </h1>
                    <PurchaseCards />
                </div>
                <div className="PurchasePage-product-list">
                    <div className="PurchasePage-product-panel-container">
                        <div className="PurchasePage-product-panel">
                            <div className="PurchasePage-product-license-type">PRIME</div>
                            <div className="PurchasePage-product-price">
                                $ <span className="major">{buyType.price.major}</span>
                                {buyType.price.postfix}
                            </div>
                            <div className="PurchasePage-oslogos">
                                <img src={LogoWindows} alt="Windows" />
                                <img src={LogoMac} alt="macOS" />
                            </div>
                            <div className="PurchasePage-buy-type">
                                <select onChange={onTypeChanged}>
                                    {StaticSyncBuyTypes.map((type, key) => {
                                        return (
                                            <option value={key} key={key}>
                                                {type.title}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <ul className="PurchasePage-feature-list">
                                <li>
                                    <strong className="RedText">Unlimited projects</strong>&nbsp;
                                    <span className="LineThrough">Create up to 5 projects</span>
                                    <br />
                                    Create projects to sync your files between local storage and cloud storage
                                </li>
                                <li>
                                    <strong className="RedText">Unlimited cloud accounts</strong>&nbsp;
                                    <span className="LineThrough">1 cloud account</span>
                                    <br />
                                    You can connect several cloud accounts and set different cloud storage targets for
                                    your project
                                </li>
                                <li>
                                    <strong className="RedText">Lifetime support</strong>
                                    <br />
                                    Awesome email support from our Happiness ambassadors
                                </li>
                                <li>
                                    <strong>Cross-Platform</strong>
                                    <br />
                                    Unlimited installs across Windows and macOS
                                </li>
                                <li>
                                    <strong>Smart way to sync</strong>
                                    <br />
                                    Synchronize only modified files, or you can even modify the synchronization strategy
                                    for individual files before each syncing
                                </li>
                                <li>
                                    <strong>Support filter</strong>
                                    <br />
                                    you can add filters to limit the synchronization of different types of files
                                </li>
                                <li>
                                    <strong>Support multiple proxy servers</strong>
                                    <br />
                                    Different projects can use different proxy server.
                                </li>
                            </ul>
                        </div>
                        <div className="PurchasePage-product-panel-footer">
                            <div className="PurchasePage-buy-button-panel">
                                <Button url={buyType.url} big caption="Buy Prime" type="Blue" />
                                <p>
                                    Or <a href="/downloads">Try free for 15 days</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="PurchasePage-product-panel-container">
                        <div className="PurchasePage-product-panel">
                            <div className="PurchasePage-product-license-type">FREE</div>
                            <div className="PurchasePage-product-price">
                                $ <span className="major">0</span>.00
                            </div>
                            <div className="PurchasePage-oslogos">
                                <img src={LogoWindows} alt="Windows" />
                                <img src={LogoMac} alt="macOS" />
                            </div>
                            <div className="PurchasePage-buy-type"></div>
                            <ul className="PurchasePage-feature-list">
                                <li>
                                    <strong>Create up to 5 projects</strong>
                                    <br />
                                    Create projects to sync your files between local storage and cloud storage
                                </li>
                                <li>
                                    <strong>1 cloud account</strong>
                                    <br />
                                    You can connect several cloud accounts and set different cloud storage targets for
                                    your project
                                </li>
                                <li>
                                    <strong>Cross-Platform</strong>
                                    <br />
                                    Unlimited installs across Windows and macOS
                                </li>
                                <li>
                                    <strong>Smart way to sync</strong>
                                    <br />
                                    Synchronize only modified files, or you can even modify the synchronization strategy
                                    for individual files before each syncing
                                </li>
                                <li>
                                    <strong>Support filter</strong>
                                    <br />
                                    you can add filters to limit the synchronization of different types of files
                                </li>
                                <li>
                                    <strong>Support multiple proxy servers</strong>
                                    <br />
                                    Different projects can use different proxy server.
                                </li>
                            </ul>
                        </div>
                        <div className="PurchasePage-product-panel-footer">
                            <div className="PurchasePage-buy-button-panel">
                                <Button big caption="Download" url="/downloads" />
                                <p>Latest version 1.3</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="PurchasePage-benefit-container">
                    <PurchaseBenefit />
                </div>
            </div>
        </div>
    );
}

const renderPurchasePanels = (panels) => {
    return panels.map((panel, index) => {
        return (
            <PurchasePanel
                key={index}
                {...panel}
            />
        );
    });
}

export function PurchaseStaticBackupPage(props) {
    console.log(props);
    const { product } = props;
    return (
        <div className="Section Purchase-bg">
            <div className="Content Purchase">
                <h1>
                    Purchase <span className="RedText">{product.title}</span>
                </h1>
                <div>Life-time license</div>
                <PurchaseCards />
                <p>{product.desc}</p>
                <div className="Purchase-discount">
                    {renderPurchasePanels(product.panels)}
                </div>
                <PurchaseBenefit />
            </div>
        </div>
    );
}
