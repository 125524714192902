import React from "react";
import "./Support.scss";

function Support() {
    return (
        <div className="Section Support-bg">
            <div className="Content Support">
                <h1>Support</h1>
                <p>If you have any question, please send your message to us, we will try to help you as soon as possible, usually in 1 or 2 business days.</p>
                <div className="Support-list">
                    <div className="Support-list-item">
                        <h2>General Support</h2>
                        <p>Any suggestions, comments, technical questions and bug reports, etc.</p>
                        <span><a href="mailto:support@staticbackup.com">support@staticbackup.com</a> ></span>
                    </div>
                    <div className="Support-list-item">
                        <h2>Order Support</h2>
                        <p>Questions regarding the status of your order, pricing and discount information, etc.</p>
                        <span><a href="mailto:support@staticbackup.com">sales@staticbackup.com</a> ></span>
                    </div>
                    <div className="Support-list-item">
                        <h2>Website Support</h2>
                        <p>Comments regarding StaticBackup Inc., or any questions about our website.</p>
                        <span><a href="mailto:support@staticbackup.com">webmaster@staticbackup.com</a> ></span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Support;
