import React, { useState } from "react";
import "./FreeUpgradeBackup8.scss";

import axios from "axios";

import Button from "./Button";

export default function FreeUpgradeBackup8() {
    const [serialKey, setSerialKey] = useState("XXXXX-XXXXX-XXXXX-XXXXX-XXXXX");
    const [FIRSTNAME, setFName] = useState("");
    const [LASTNAME, setLName] = useState("");
    const [EMAIL, setEmail] = useState("");
    const [secret, setSecret] = useState("");
    const [status, setStatus] = useState("normal");
    const handleFNameChange = event => {
        setFName(event.target.value);
    };
    const handleLNameChange = event => {
        setLName(event.target.value);
    };
    const handleEmailChange = event => {
        setEmail(event.target.value);
    };
    const handleSecretChange = event => {
        setSecret(event.target.value);
    };
    const handleUpgradeClick = event => {
        const postUrl = "https://api.staticbackup.com/freeupgradebackup8/" + secret;
        setStatus("busy");
        axios
            .post(postUrl, { FIRSTNAME, LASTNAME, EMAIL })
            .then(res => {
                setSerialKey(res.data);
                setStatus("normal");
            })
            .catch(err => {
                console.error(err);
                setSerialKey("Wrong input! Please fill all fields and try again!");
                setStatus("normal");
            });
    };
    const buttonType = status === "normal" ? null : "Gray";
    return (
        <div className="Section FreeUpgradeBackup8-bg">
            <div className="Content FreeUpgradeBackup8">
                <h1>Free upgrade to Backup8</h1>
                <p>
                    Now we provide a free upgrade plan to Backup8, please enter your name and email below, you will
                    immediately receive Backup8 license information.
                </p>
                <div className="FreeUpgradeBackup8-fields">
                    <input
                        className="FreeUpgradeBackup8-input"
                        type="text"
                        placeholder="First name"
                        onChange={handleFNameChange}
                    />
                    <input
                        className="FreeUpgradeBackup8-input"
                        type="text"
                        placeholder="Last name"
                        onChange={handleLNameChange}
                    />
                    <input
                        className="FreeUpgradeBackup8-input"
                        type="text"
                        placeholder="Email address"
                        onChange={handleEmailChange}
                    />
                    <input
                        className="FreeUpgradeBackup8-input"
                        type="text"
                        placeholder="Secret you have received"
                        onChange={handleSecretChange}
                    />
                    <div className="FreeUpgradeBackup8-button-panel">
                        <Button caption="Upgrade" onClick={handleUpgradeClick} type={buttonType} />
                        <h3>SN: {serialKey}</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}
