import ImgAccount from "./backup9imgs/account.png";
import ImgChrome from "./backup9imgs/chrome.png";
import ImgFirefox from "./backup9imgs/firefox.png";
import ImgSafari from "./backup9imgs/safari.png";
import ImgFonts from "./backup9imgs/fonts.png";
import ImgKeychain from "./backup9imgs/keychain.png";
import ImgMailData from "./backup9imgs/maildata.png";
import ImgMailPrefs from "./backup9imgs/mailprefs.png";
import ImgDocuments from "./backup9imgs/docs.png";

export const SUPPORT_FILES = [
    { name: "Documents", img: ImgDocuments },
    { name: "Desktop files", img: ImgDocuments },
    { name: "Safari", img: ImgSafari },
    { name: "Chrome", img: ImgChrome },
    { name: "Firefox", img: ImgFirefox },
    { name: "Internet accounts", img: ImgAccount },
    { name: "Mail", img: ImgMailData },
    { name: "Mail preferences", img: ImgMailPrefs },
    { name: "Thunderbird", img: ImgMailData },
    { name: "Keychain", img: ImgKeychain },
    { name: "Fonts", img: ImgFonts }
];
