import React from "react";
import "./Backup8Welcome.scss";

import Button from "./Button";

function Backup8welcome() {
    return (
        <div className="Section Backup8Welcome-bg">
            <div className="Content Backup8Welcome">
                <h1 className="AdWords CenterText">Backup data from<br />famous browsers, email clients</h1>
                <Button caption="Try it free" url="/download/backup8" />
                <p className="SmallText Bold">20-day trial, Windows XP/Vista/7/8/10</p>
            </div>
        </div >
    );
}

export default Backup8welcome;
